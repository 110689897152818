import React, { useState, Fragment } from 'react';
import { Section, Cta } from 'components';
import { ReactComponent as Cross } from '../../images/icons/cross.svg';

import * as styles from './styles.module.scss';

const TwoColsTextFilter = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    border_bottom: borderBottom,
    border_top: borderTop,
    one_col: oneCol,
  } = primary;

  const [activeIndex, setActiveIndex] = useState(0);

  const activeItem = items[activeIndex];

  const SwitcherButton = ({ item, index }) => {
    const isActive = index === activeIndex;
    return (
      <div
        className={isActive ? styles.rowSwitcherActive : styles.rowSwitcher}
        onClick={() => {
          setActiveIndex(index);
        }}
        role="button"
        tabIndex="0"
      >
        <a className={styles.buttonSwitcher}>
          <div className={styles.iconColumn}>
            <Cross height={40} width={40} className={styles.arrowRight} />
          </div>

          <div dangerouslySetInnerHTML={{ __html: item?.title?.html }} className={styles.title} />
        </a>
      </div>
    );
  };

  const ActiveDescription = () => {
    const { cta_link: activeCtaLink, cta_text: activeCtaText } = activeItem || {};
    const hasCta = activeCtaText?.text;

    return (
      <div>
        <p>{activeItem?.description?.text}</p>
        {hasCta && <Cta text={activeCtaLink?.text} url={activeCtaText?.url} />}
      </div>
    );
  };

  const oneColVariant = oneCol ? styles.oneColVariant : '';

  return (
    <Section
      containerClassName={styles.container}
      className={`${oneColVariant} two-cols-text-filter ${styles.containerClass}`}
      sliceName="TwoColsTextFilter"
    >
      <div className={styles.wrapped} style={{ borderBottom: !borderBottom && 0, borderTop: !borderTop && 0 }}>
        {ctaText?.text && (
          <div className={styles.ctaContainer}>
            <Cta text={ctaText?.text} url={ctaLink?.url} isPinkArrow isNoLink />
          </div>
        )}
        <div className={styles.row}>
          <div className={styles.leftCol}>
            {items?.map((item, index) => {
              const isActive = index === activeIndex;
              return (
                <Fragment key={item?.title?.text}>
                  <SwitcherButton item={item} index={index} />
                  {oneCol && (
                    <div
                      className={`${isActive ? styles.oneColDescActive : ''} ${styles.oneColDescription}`}
                      id="description"
                    >
                      <ActiveDescription />
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
          {!oneCol && (
            <div className={styles.rightCol} id="description">
              {items?.map((item, index) => {
                const isActive = index === activeIndex;
                return (
                  <div
                    className={`${isActive ? styles.rightColDescActive : ''} ${styles.rightColDesc}`}
                    id="description"
                  >
                    <ActiveDescription />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};
export default TwoColsTextFilter;
