import React from 'react';
import { Link } from 'components';
import moment from 'moment';
import { ReactComponent as CtaArrowRightGrey } from '../../../images/icons/arrow-right-grey.svg';
import * as styles from '../styles.module.scss';

const ListItem = ({ item, firstItem }) => {
  const { content: itemContent, cta_link: itemCtaLink, cta_text: itemCtaText, date, title } = item;

  const page = process.env.GATSBY_SITE_NAME;

  const formattedDate = (_date) => {
    const inputDay = moment(_date).startOf('day');
    return { monthYear: `${inputDay.format('MMM.YY')}`, day: `${inputDay.format('D')}` };
  };

  return (
    <div className={`${styles.itemContainer} ${firstItem ? styles.firstItem : ''}`}>
      <div className={styles.dateRow}>
        <div>{formattedDate(date)?.day?.toUpperCase()}</div>
        <CtaArrowRightGrey height={20} width={20} className={styles.arrowRight} />
        <div>{formattedDate(date)?.monthYear?.toUpperCase()}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.contentWrapper}>
          {title.html && (
            <Link to={itemCtaLink?.url}>
              <h3 className={styles.listItemTitle}>{title?.text}</h3>
            </Link>
          )}
          <div>
            <div className={styles.contentTextItem}>
              <p>{itemContent.text || itemContent}</p>
            </div>
          </div>
        </div>
        <Link className={styles.formButton} to={itemCtaLink?.url}>
          <button type="submit" className={styles.mainCtaLight}>
            {itemCtaText?.text}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ListItem;
