/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { graphql, useStaticQuery } from 'gatsby';
// Using relative import for components to fix weird HMR bug
import FormField from '../FormField';
import Link from '../Link';
import Scrambler from '../Scrambler';
import OnVisible from '../OnVisible';
import { ReactComponent as LogoSecondary } from '../../images/icons/logo-secondary.svg';
import { ReactComponent as LogoFilmsSecondary } from '../../images/icons/logo-films-secondary.svg';
import { ReactComponent as ArrowRightLarge } from '../../images/icons/arrow-right-large.svg';

import * as styles from './styles.module.scss';

const Footer = (props) => {
  const { customFooterTagline, slug } = props;
  const isFilms = process.env.GATSBY_SITE_NAME === 'films';
  const [isVisible, setIsVisible] = useState(false);
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });

  const [submitting, setSubmitting] = useState(false);
  const [pathName, setPathName] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [somethingElse, setSomethingElse] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const data = useStaticQuery(graphql`
    query queryName {
      prismicSettings {
        data {
          acknowledgement {
            text
            html
          }
          address {
            html
            text
          }
          contact_email {
            html
            text
          }
          contact_email_films {
            html
            text
          }
          instagram {
            url
          }
          instagram_films {
            url
          }
          linkedin {
            url
          }
          linkedin_films {
            url
          }
          phone_number {
            text
            html
          }
          films_phone_number {
            text
            html
          }
          form_email_placeholder {
            html
            text
          }
          form_message_placeholder {
            html
            text
          }
        }
      }
    }
  `);

  const {
    prismicSettings: { data: settingsData },
  } = data;

  const {
    acknowledgement,
    address,
    contact_email: email,
    instagram,
    linkedin,
    phone_number: phone,
    films_phone_number: filmsPhone,
    linkedin_films: linkedInFilms,
    instagram_films: instagramFilms,
    contact_email_films: filmEmail,
    form_email_placeholder: emailPlaceholder,
    form_message_placeholder: messagePlaceholder,
  } = settingsData;

  const { errors, dirtyFields } = formState;

  useEffect(
    () =>
      // Set submitting to false in clean up function
      () => {
        setSubmitting(false);
      },
    []
  );

  useEffect(() => {
    if (somethingElse) {
      setTimeout(() => {
        setSomethingElse(false);
        setSubmissionSuccess(false);
      }, 2000);
    }
  }, [somethingElse]);

  const fields = [
    {
      type: 'email',
      name: 'email',
      placeholder: `${emailPlaceholder?.text || 'What’s your email?'}`,
      label: 'Email address',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'text',
      name: 'message',
      placeholder: `${messagePlaceholder?.text || "What's your message?"}`,
      label: 'message',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please type a message',
    },
  ];

  const onSubmit = async (values, event) => {
    setSubmitting(true);
    try {
      const url = 'https://submit-form.com/prO3zaO2';
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(values),
      };
      const response = await fetch(url, config);
      if (response.ok) {
        setSubmitting(false);
        setSubmissionSuccess(true);
        setTimeout(() => {
          setSomethingElse(true);
          event.target.reset();
        }, 2000);
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Oops something went wrong, please try again');
      setSubmitting(false);
    }
  };

  const formatPhone = (number) =>
    number
      .split('')
      .filter((item) => item !== ' ' && item !== '+')
      .join('');

  const phoneToUse = isFilms ? filmsPhone?.text : phone?.text;
  const linkedInToUse = isFilms ? linkedInFilms?.url : linkedin?.url;
  const instagramToUse = isFilms ? instagramFilms?.url : instagram?.url;
  const notSubmitting = !submitting && !submissionSuccess && !somethingElse;
  const isPageWithCustomFooterTagline = slug === customFooterTagline?.uid;
  const defaultFooterTagline = isFilms ? 'Perth & Melbourne' : 'Perth';

  const getFooterTagline = () => {
    if (isPageWithCustomFooterTagline && customFooterTagline?.text) {
      return customFooterTagline?.text;
    }
    return defaultFooterTagline;
  };

  return (
    <OnVisible as="footer" className={styles.footer} onChange={setIsVisible}>
      <div className={isFilms ? styles.rowTop : styles.rowTopFilms}>
        {isFilms ? (
          <div className={styles.iconWrapper}>
            <LogoFilmsSecondary className={styles.iconLogoFilms} />
          </div>
        ) : (
          <div className={styles.iconWrapperAgency}>
            <LogoSecondary className={styles.iconLogo} />
          </div>
        )}
      </div>
      <div className={styles.titleRow}>
        {submitting && <Scrambler text="One Sec..." visible className={styles.successMessage} />}
        {submissionSuccess && !somethingElse && (
          <Scrambler text="Thank You!" visible className={styles.successMessage} />
        )}
        {somethingElse && <Scrambler text="Talk to us" visible className={styles.successMessage} />}
        {notSubmitting && <span className={styles.successMessage}>Talk to us</span>}
        <div className={styles.iconWrapperArrow} id="talktoUs">
          <ArrowRightLarge className={styles.icon} />
        </div>
      </div>
      <div className={`container ${styles.container}`}>
        <form className={styles.columnOne} onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field) => {
            const hasError = dirtyFields[field.name] && errors[field.name];
            return (
              <div key={field.label} className={`${styles.field} ${field.className || ''}`}>
                <FormField {...field} register={register} />
                <div className={`${styles.fieldError} ${hasError ? 'active' : 'inactive'}`}>
                  {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
                </div>
              </div>
            );
          })}
          <button type="submit" className={styles.formButton} disabled={submitting || submissionSuccess}>
            {submitting ? 'SUBMITTING' : 'SUBMIT IT'}
          </button>
        </form>
        <div className={styles.columnTwo}>
          <span className={styles.agencyText}>RHYTHM {isFilms ? 'FILMS' : ''}</span>
          <span className={styles.agencyText}>{getFooterTagline()}</span>
          <div>
            <div className={styles.row}>
              <div className={styles.addressColumn}>
                {isFilms ? (
                  <span className={styles.link}>Executive Producer Candice Shields</span>
                ) : (
                  <Link
                    to="https://www.google.com/maps/place/Rhythm+Creative+Content/@-31.946494,115.8323222,15z/data=!4m5!3m4!1s0x0:0x1773eb31877f23d0!8m2!3d-31.946494!4d115.8323222"
                    className={styles.link}
                  >
                    {address?.text}
                  </Link>
                )}
                {isFilms ? (
                  <a href={`mailto:${filmEmail.text}`} className={styles.link}>
                    {filmEmail?.text}
                  </a>
                ) : (
                  <a href={`mailto:${email.text}`} className={styles.link}>
                    {email?.text}
                  </a>
                )}
                <a href={`tel:${formatPhone(phoneToUse)}`} className={styles.link}>
                  {phoneToUse}
                </a>
              </div>
              <div className={styles.socialColumn}>
                <div className={styles.socials}>
                  <Link to={instagramToUse} className={styles.link}>
                    Insta
                  </Link>
                  <Link to={linkedInToUse} className={styles.link}>
                    Linkedin
                  </Link>
                </div>
                <span className={styles.dateContainer}>©{new Date().getFullYear()}</span>
              </div>
            </div>
          </div>
          {acknowledgement?.text && <p className={styles.footerText}>{acknowledgement?.text}</p>}
        </div>
      </div>
    </OnVisible>
  );
};

export default Footer;
