import React from 'react';
import { Cta, Image } from 'components';
import * as styles from '../styles.module.scss';

const TitleBottom = ({ primary, isProjectGallery }) => {
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    image,
    secondary_cta_text: secondaryCtaText,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_alignment: secondaryCtaAlignment,
    name_credit: nameCredit,
    client_credit: companyCredit,
  } = primary;
  const titleHtml = title?.html;
  const hasCta = ctaText?.text;
  const hasSecondaryCta = secondaryCtaText?.text;

  return (
    <div>
      {hasSecondaryCta && (
        <div className={secondaryCtaAlignment === 'Top' ? styles.ctaSecondaryRowTop : styles.ctaSecondaryRow}>
          <Cta text={secondaryCtaText?.text} url={secondaryCtaLink?.url} isPinkArrow removePadding isNoLink />
        </div>
      )}
      <div className={isProjectGallery ? styles.rowGallery : styles.row}>
        <div className={styles.column}>
          <Image image={image} useDistortion />
          <div className={isProjectGallery ? styles.titleCtaRowGallery : styles.titleCTARow}>
            <div className={styles.infoContainer}>
              {titleHtml && (
                <div
                  dangerouslySetInnerHTML={{ __html: titleHtml }}
                  className={isProjectGallery ? styles.titleGallery : styles.title}
                />
              )}
              {(nameCredit?.text || companyCredit?.text) && (
                <div className={styles.creditsContainer}>
                  <span className={styles.nameCredit}>{nameCredit.text}</span>
                  <span className={styles.companyCredit}>{companyCredit.text}</span>
                </div>
              )}
            </div>
            {hasCta && <Cta text={ctaText?.text} url={ctaLink?.url} removePadding ifOffWhiteArrow />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleBottom;
