import React, { useEffect } from 'react';
import { animate } from 'motion';
import { Footer, Header, SEO, NoJs, WmAscii, BackgroundElements } from 'components';
import 'typeface-lato';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const {
    children,
    customSEO,
    seo,
    hideEscapeRoutes,
    location,
    isFilms,
    bgElements,
    handCursor,
    paddingBottom,
    mainClassName,
    customFooterTagline,
  } = props;

  const slug = location?.pathname;

  // --------Cursor--------------------------------

  useEffect(() => {
    const moveCursor = (e) => {
      const x = e.clientX - 16;
      const y = e.clientY - 16;

      animate('#cursor', { transform: `translate3d(${x}px, ${y}px, 0)` }, { duration: 0, easing: 'ease-in-out' });
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  return (
    <>
      <NoJs />
      {/* CURSOR */}
      <div
        className={`cursor ${handCursor ? 'hand-cursor' : ''}`}
        id="cursor"
        style={{
          transform: `translate3d(-100px, -100px, 0)`,
        }}
      />
      <WmAscii />
      <main
        className={`${paddingBottom ? 'main-padding-bottom' : ''} ${isFilms ? 'is-films' : ''}${
          hideEscapeRoutes ? 'hide-escape-routes' : ''
        } ${mainClassName || ''}`}
      >
        <div className="main-fade">
          {!hideEscapeRoutes && <Header slug={slug} hideEscapeRoutes={hideEscapeRoutes} />}
          <div className="noise-wrapper">
            <div className="noise" />
          </div>
          <BackgroundElements bgElements={bgElements} />
          {/* NOISE BG */}
          <div className="main-wrapper" style={{ opacity: 1 }} id="page">
            {/* SEO */}
            {!customSEO && <SEO {...seo} />}
            {children}
          </div>
          <div className="black-and-white-bars" />
        </div>
      </main>
      {!hideEscapeRoutes && <Footer slug={slug} customFooterTagline={customFooterTagline} />}
      <div id="scrollTo" />
    </>
  );
};

export default Layout;
