import React, { useState } from 'react';
import { Section, Noise } from 'components';
import { VideoBottom, VideoTop, Content } from './components';
import * as styles from './styles.module.scss';

const LargeImageText = (props) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const [videoState, setVideoState] = useState(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const { data, isProject, firstSlice } = props;
  const { primary } = data;

  const {
    text_alignment: textAlignment,
    video_aspect_ratio: aspectRatio,
    light_background: lightBackground,
    mute_button: muteButton,
  } = primary;

  const imageAspectRatio = aspectRatio ? styles.isSixteenNine : styles.isTwentyNine;

  const isLandingFilms = process.env.GATSBY_IS_LANDING && process.env.GATSBY_SITE_NAME === 'films';
  const isTopText = textAlignment === 'Top';
  const isWhiteBg = lightBackground ? styles.whiteBg : '';
  const isFirstSlice = firstSlice ? styles.isFirstSlice : '';
  const isProjectHeader = isProject ? styles.isProject : '';
  const isFilms = process.env.GATSBY_SITE_NAME === 'films';

  const videoProps = {
    primary,
    videoState,
    setVideoState,
    setVideoVisible,
    videoVisible,
    setVideoLoaded,
    isProject,
  };

  return (
    <Section
      containerClassName={styles.container}
      className={`${imageAspectRatio} ${styles.sectionSmallerSplash} ${isWhiteBg} ${isFirstSlice} ${isProjectHeader}`}
      sliceName="LargeImageText"
    >
      {lightBackground && <Noise />}
      {!isTopText && (
        <VideoTop
          videoProps={videoProps}
          isTopText={isTopText}
          lightBackground={lightBackground}
          isLandingFilms={isLandingFilms}
          muteButton={muteButton}
          isFilms={isFilms}
        />
      )}
      <Content
        primary={primary}
        videoProps={videoProps}
        isLandingFilms={isLandingFilms}
        lightBackground={lightBackground}
        muteButton={muteButton}
      />
      {isTopText && (
        <VideoBottom
          videoProps={videoProps}
          isLandingFilms={isLandingFilms}
          isTopText={isTopText}
          lightBackground={lightBackground}
          muteButton={muteButton}
          isFilms={isFilms}
        />
      )}
    </Section>
  );
};

export default LargeImageText;
