// extracted by mini-css-extract-plugin
export var sectionSmaller = "styles-module--sectionSmaller--2DciA";
export var isProject = "styles-module--isProject--2aD-l";
export var title = "styles-module--title--bs9y3";
export var isFirstSlice = "styles-module--isFirstSlice--dyubv";
export var sectionSmallerSplash = "styles-module--sectionSmallerSplash--wnxkk";
export var whiteBg = "styles-module--whiteBg--_XCyn";
export var CtaContainerBottom = "styles-module--CtaContainerBottom--3tVo-";
export var titlePink = "styles-module--titlePink--2CmhR";
export var squiggleRowBottom = "styles-module--squiggleRowBottom--hBBZY";
export var container = "styles-module--container--1XBr3";
export var ctaRow = "styles-module--ctaRow--lpx9G";
export var videoCtaWrapper = "styles-module--videoCtaWrapper--3FQ0e";
export var column = "styles-module--column--2XNO2";
export var columnWrapper = "styles-module--columnWrapper--1CmHb";
export var columnPlay = "styles-module--columnPlay--1qjEv";
export var columnPlayRight = "styles-module--columnPlayRight--2mRYk";
export var row = "styles-module--row--z-ZiL";
export var rowReverse = "styles-module--rowReverse--2EV1_";
export var CtaContainer = "styles-module--CtaContainer--uT2xT";
export var ctaContainerBottom = "styles-module--ctaContainerBottom--2ypiA";
export var ctaContainerTop = "styles-module--ctaContainerTop--2pTRL";
export var text = "styles-module--text--1_cdT";
export var textPink = "styles-module--textPink--ksuES";
export var isSixteenNine = "styles-module--isSixteenNine--2lehp";
export var imageContainer = "styles-module--imageContainer--2o__X";
export var isTwentyNine = "styles-module--isTwentyNine--AIOOS";
export var muteContainer = "styles-module--muteContainer--1ErM8";
export var muteButton = "styles-module--muteButton--QIcNS";
export var muteButtonFilms = "styles-module--muteButtonFilms--292ZN";
export var colorBar = "styles-module--colorBar--8W0fl";
export var barContainer = "styles-module--barContainer--1yf-2";
export var barContainerProject = "styles-module--barContainerProject--3lEja";
export var player = "styles-module--player--1qKvX";
export var playRow = "styles-module--playRow--kFjBs";
export var playText = "styles-module--playText--27QJM";
export var playTextPink = "styles-module--playTextPink--23Tjt";
export var buttonPlay = "styles-module--buttonPlay--13bq6";
export var buttonPause = "styles-module--buttonPause--2i3b_";
export var buttonPlayPink = "styles-module--buttonPlayPink--EzyGL";
export var buttonPausePink = "styles-module--buttonPausePink--2gqhJ";
export var buttonWrapper = "styles-module--buttonWrapper--3sVjD";
export var contentCta = "styles-module--contentCta--HvKdF";