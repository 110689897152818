import React from 'react';
import { Cta, Image } from 'components';
import * as styles from '../styles.module.scss';

const TitleCenter = ({ primary, isProjectGallery }) => {
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    image,
    secondary_cta_text: secondaryCtaText,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_alignment: secondaryCtaAlignment,
    name_credit: nameCredit,
    client_credit: companyCredit,
  } = primary;
  const titleHtml = title?.html;
  const hasCta = ctaText;
  const hasSecondaryCta = secondaryCtaText;

  return (
    <div>
      {hasSecondaryCta && secondaryCtaAlignment === 'Top' && (
        <div className={styles.ctaWrapper}>
          <div className={secondaryCtaAlignment === 'Bottom' && styles.ctaSecondaryRow}>
            <Cta
              text={secondaryCtaText?.text}
              url={secondaryCtaLink?.url}
              isPinkArrow
              removePadding
              className={styles.ctaRowCenterVariant}
              isNoLink
            />
          </div>
        </div>
      )}
      <div className={styles.rowCenterVariant}>
        <div className={styles.titleColumnCenter}>
          <div
            dangerouslySetInnerHTML={{ __html: titleHtml }}
            className={isProjectGallery ? styles.titleCenterGallery : styles.titleCenterVariant}
          />
          {(nameCredit?.text || companyCredit?.text) && (
            <div className={styles.creditsContainer}>
              <span className={styles.nameCredit}>{nameCredit.text}</span>
              <span className={styles.companyCredit}>{companyCredit.text}</span>
            </div>
          )}
        </div>
        <div className={styles.titleWraper}>
          {hasCta && <Cta text={ctaText?.text} url={ctaLink?.url} className={styles.ctaRowCenterVariant} />}
        </div>
        <div className={styles.imageContainerLeft}>
          <Image image={image} useDistortion />
        </div>
      </div>
    </div>
  );
};

export default TitleCenter;
