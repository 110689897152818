// extracted by mini-css-extract-plugin
export var containerClassPadding = "styles-module--containerClassPadding--2qNFi";
export var row = "styles-module--row--1qD-E";
export var fullColumn = "styles-module--fullColumn--3GrE6";
export var halfColumn = "styles-module--halfColumn--3xD3c";
export var fullColVideo = "styles-module--fullColVideo--2Zot4";
export var horizontalQuarter = "styles-module--horizontalQuarter--2bLpe";
export var quarterWidth = "styles-module--quarterWidth--2o23-";
export var columnText = "styles-module--columnText--1w4M5";
export var playButtonWrapper = "styles-module--playButtonWrapper--3mYa3";
export var columnTextInner = "styles-module--columnTextInner--1J14r";
export var imageEffectContainer = "styles-module--imageEffectContainer--3NA-j";
export var imageEffectContainerTop = "styles-module--imageEffectContainerTop--21Wkt";
export var textItem = "styles-module--textItem--2uxV9";
export var itemWrapper = "styles-module--itemWrapper--3uyIv";
export var itemWrapperHorizontal = "styles-module--itemWrapperHorizontal--3Ng5y";
export var description = "styles-module--description--3UArX";
export var containerClass = "styles-module--containerClass--17ahi";
export var container = "styles-module--container--WhIki";
export var containerHidden = "styles-module--containerHidden--27V_m";
export var wrapped = "styles-module--wrapped--1I_6g";
export var ctaContainer = "styles-module--ctaContainer--3K6N9";
export var containerGalleryBox = "styles-module--containerGalleryBox--rmBR5";
export var playButtonContainer = "styles-module--playButtonContainer--2LnwS";
export var playButtonWrapperContainer = "styles-module--playButtonWrapperContainer--2SFBO";
export var playButton = "styles-module--playButton--95fls";
export var playButtonOuter = "styles-module--playButtonOuter--1hKpx";