// extracted by mini-css-extract-plugin
export var containerClass = "styles-module--containerClass--3PgsO";
export var wrapped = "styles-module--wrapped--_hTPf";
export var oneColVariant = "styles-module--oneColVariant--2nM2y";
export var row = "styles-module--row--p4ONq";
export var arrowRight = "styles-module--arrowRight--3BvoB";
export var oneColDescription = "styles-module--oneColDescription--T0Br4";
export var oneColDescActive = "styles-module--oneColDescActive--1S14P";
export var rowSwitcher = "styles-module--rowSwitcher--2EkB6";
export var rightColDesc = "styles-module--rightColDesc--M2cAE";
export var rightColDescActive = "styles-module--rightColDescActive--1FGWP";
export var ctaContainer = "styles-module--ctaContainer--3sA4X";
export var rowSwitcherActive = "styles-module--rowSwitcherActive--2qX3-";
export var title = "styles-module--title--3EK8D";
export var leftCol = "styles-module--leftCol--25yj-";
export var rightCol = "styles-module--rightCol---Ejee";
export var mainCta = "styles-module--mainCta--3Jn09";
export var iconColumn = "styles-module--iconColumn--36EZK";
export var container = "styles-module--container--PX6xg";
export var containerInvisible = "styles-module--containerInvisible--3ZA7L";
export var ctaRow = "styles-module--ctaRow--2D9l1";
export var buttonSwitcher = "styles-module--buttonSwitcher--K_l9g";