import React from 'react';
import * as styles from './styles.module.scss';

const Noise = ({ dark }) => (
  <div className={`${styles.noiseWrapper} ${dark ? styles.darkWrapper : ''}`}>
    <div className={`${styles.noise} ${dark ? styles.darkNoise : ''}`} />
  </div>
);

export default Noise;
