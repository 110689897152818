import React, { Fragment } from 'react';
import { Section, Image, Cta, OnVisible } from 'components';
import SimpleReactLightbox from 'simple-react-lightbox-pro';
import GalleryBox from '../GalleryGrid/components/GalleryBox';
import * as styles from './styles.module.scss';

const TextImageWithVideoStrip = (props) => {
  const { data } = props;
  const { items, primary } = data;
  const { title } = primary;
  const hasTitle = title?.text;

  return (
    <Section sliceName="TextImageWithVideoStrip" containerClassName={styles.container}>
      {hasTitle && <Cta isPinkArrow isNoLink text={hasTitle} className={styles.cta} />}
      <div className={styles.itemsContainer}>
        {items.map((item) => {
          const {
            title: cardTitle,
            subtitle,
            image,
            content,
            video1,
            video2,
            video3,
            video4,
            video1_placeholder: video1Image,
            video2_placeholder: video2Image,
            video3_placeholder: video3Image,
            video4_placeholder: video4Image,
            v1job,
            v2job,
            v3job,
            v4job,
            v1client,
            v2client,
            v3client,
            v4client,
          } = item;
          const videos = [
            { video: video1, placeholder: video1Image, job: v1job, client: v1client },
            { video: video2, placeholder: video2Image, job: v2job, client: v2client },
            { video: video3, placeholder: video3Image, job: v3job, client: v3client },
            { video: video4, placeholder: video4Image, job: v4job, client: v4client },
          ];
          return (
            <Fragment key={cardTitle?.text}>
              <OnVisible className={styles.item} visibleClassName={styles.visibleItem}>
                <div className={styles.imageAndText}>
                  <Image image={image} className={styles.image} />
                  <div className={styles.textContainer}>
                    {cardTitle?.text && (
                      <div dangerouslySetInnerHTML={{ __html: cardTitle?.html }} className={styles.title} />
                    )}
                    {subtitle?.text && (
                      <div dangerouslySetInnerHTML={{ __html: subtitle?.html }} className={styles.subtitle} />
                    )}
                    {content?.text && (
                      <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />
                    )}
                  </div>
                </div>
                <div className={styles.videoStrip}>
                  {videos?.map((videoItem) => (
                    <div className={styles.videoItem}>
                      <div className={styles.videoItemVideo}>
                        <Image image={videoItem?.placeholder} className={`"grid"`} />
                        <div className={styles.containerGalleryBox}>
                          {videoItem?.video?.url && (
                            <SimpleReactLightbox>
                              <GalleryBox
                                playButtonClassName={styles.smallPlayButton}
                                imagePlaceholder={videoItem?.placeholder}
                                lightBoxVideo={videoItem?.video?.url}
                              />
                            </SimpleReactLightbox>
                          )}
                        </div>
                      </div>
                      <div className={styles.textContent}>
                        <span className={styles.jobType}>{videoItem?.job?.text}</span>
                        <span className={styles.client}>{videoItem?.client?.text}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </OnVisible>
            </Fragment>
          );
        })}
      </div>
    </Section>
  );
};
export default TextImageWithVideoStrip;
