import React from 'react';
import * as styles from '../styles.module.scss';

const PlayButton = ({ primary, videoState, setVideoState, isTopText, lightBackground }) => {
  const { title_alignment: textAlignment } = primary;

  return (
    <div
      className={isTopText ? styles.playRow : ''}
      style={{
        justifyContent: textAlignment === 'Bottom Right' && 'flex-start',
        paddingLeft: textAlignment === 'Bottom Right' && '2em',
      }}
    >
      <div>
        <div />
      </div>
      <div className={styles.buttonWrapper} onClick={() => setVideoState(!videoState)} role="button" tabIndex={0}>
        {videoState ? (
          <h2 className={lightBackground ? styles.playTextPink : styles.playText}>Pause</h2>
        ) : (
          <h2 className={lightBackground ? styles.playTextPink : styles.playText}>Play</h2>
        )}
        <div className={styles.iconWrapper}>
          {lightBackground ? (
            <div className={videoState ? styles.buttonPausePink : styles.buttonPlayPink}>
              <br />
              <br />
            </div>
          ) : (
            <div className={videoState ? styles.buttonPause : styles.buttonPlay}>
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayButton;
