import React from 'react';
import { Image } from 'components';
import * as styles from '../styles.module.scss';

const ImageQuarterHorizontal = ({ items }) => (
  <>
    {items[items?.length - 2]?.item_is_text?.text ? (
      <div className={styles.textItem}>
        <h4>{items[items?.length - 2]?.item_is_text?.text}</h4>
      </div>
    ) : (
      <Image image={items[items?.length - 2]?.image} className="grid" />
    )}

    {items[items?.length - 1]?.item_is_text?.text ? (
      <div className={styles.textItem}>
        <h4>{items[items?.length - 1]?.item_is_text?.text}</h4>
      </div>
    ) : (
      <Image image={items[items?.length - 1]?.image} className="grid" />
    )}
  </>
);

export default ImageQuarterHorizontal;
