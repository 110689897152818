module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Rhythm', // Default Site Title used for SEO & PWA
  description:
    'If you want to get people to listen, tell them a story. Make it real or sad. Funny or shocking. Make them feel something and they will click, share, buy and even adore. We’d love to help you tell a story about your brand or latest initiative.', // Default Site Decription used for SEO
  siteName: 'Rhythm', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/open-graph.jpg', // Default OpenGraph image
  ogLanguage: 'en_AU', // Facebook Language
  socialLinks: [
    'https://www.instagram.com/rhythmagency_/',
    'https://vimeo.com/user5910871',
    'https://www.linkedin.com/company/rhythm-content/',
  ], // Array of social links (facebook, insta, etc)
  // JSONLD / Manifest
  icon: 'static/icon-rhythm.png', // Used for manifest favicon, splash screen, and icon generation
  shortName: 'Rhythm', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Rhythm', // Author for schemaORGJSONLD
  themeColor: '#090909', // PWA Icon background & address bar colour if installed on desktop
  backgroundColor: '#090909', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet
  twitter: '', // Twitter Username
};
