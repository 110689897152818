const siteServices = {
  films: [
    'All',
    'Cinematography',
    'Photography',
    'Scripting',
    'Direction',
    'Editing',
    'Producing',
    'Colour grade',
    'Motion Graphics',
    'Visual Effects',
    'Sound Design',
  ],
  agency: [
    'All',
    'Advertising',
    'Strategy',
    'Concepting',
    'Branding',
    'Design',
    'Digital & Social',
    'Print Production',
    'Film & TV Production',
    'Motion Graphics',
    'Visual Effects',
    'Editing',
    'Photography',
  ],
};

export default siteServices;
