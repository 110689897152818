import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './styles.scss';

const BackgroundElements = ({ bgElements }) => (
  <>
    {/* WRITING LEFT */}
    {bgElements?.writingLeft && (
      <StaticImage
        src="../../images/bg_assets/writing-left.png"
        className="film-writing-left"
        placeholder="none"
        alt="Writing Left"
      />
    )}
    {/* SMILEY RIGHT */}
    {bgElements?.smileyRight && (
      <StaticImage
        src="../../images/bg_assets/SMILEY-CROWN.png"
        className="smiley-crown-img"
        placeholder="none"
        alt="Smiley with crown"
      />
    )}
    {/* FILM REEL */}
    {bgElements?.filmReel && (
      <StaticImage
        src="../../images/bg_assets/FILM-REEL.png"
        className="film-reel-img"
        placeholder="none"
        alt="Film reel"
        imgStyle={{ willChange: 'auto' }}
      />
    )}
    {/* WRITING BOTTOM */}
    {bgElements?.writingBottom && (
      <StaticImage src="../../images/squiggles.png" className="film-writing-img" placeholder="none" alt="Squiggles" />
    )}
    {/* FILM WRITING */}
    {bgElements?.filmWriting && (
      <StaticImage
        src="../../images/bg_assets/FILM-WRITING-3.png"
        className="film-writing-3-img"
        placeholder="none"
        alt="Film marker writing"
      />
    )}
    {/* YELLOW SQUIGGLE */}
    {bgElements?.yellowSquiggle && (
      <StaticImage
        src="../../images/bg_assets/GRAFFITI-YELLOW-SQUIGGLE.png"
        className="yellow-squiggle-img"
        placeholder="none"
        alt="Graffiti"
      />
    )}
    {/* YELLOW SPLATTER */}
    {bgElements?.yellowSplatter && (
      <StaticImage
        src="../../images/bg_assets/YELLOW-SPLATTER.png"
        className="yellow-splat-img"
        placeholder="none"
        alt="Yellow Splatter"
      />
    )}
    {/* PINK GREY GRAFFITI */}
    {bgElements?.pinkGreyGraffiti && (
      <StaticImage
        src="../../images/bg_assets/BLUE-STRIP-GRAPHIC.png"
        className="grey-pink-graffiti-img"
        placeholder="none"
        alt="Pink Graffiti"
      />
    )}
    {/* WRITING RIGHT */}
    {bgElements?.writingRight && (
      <StaticImage
        src="../../images/bg_assets/FILM-WRITING.png"
        className="writing-right-img"
        placeholder="none"
        alt="cursive writing swuiggles"
      />
    )}
    {/* YELLOW GRAFITI SPLASH LEFT */}
    {bgElements?.hasYellowSplatterLeft && (
      <StaticImage
        src="../../images/bg_assets/YELLOW-GRAFFITI-SPLASH.png"
        className="yellow-splash-left-img"
        placeholder="none"
        alt="yellow graffiti splash"
      />
    )}
    {/* YELLOW GRAFITI SPLASH RIGHT */}
    {bgElements?.yellowSplatterRight && (
      <StaticImage
        src="../../images/bg_assets/YELLOW-GRAFFITI-SPLASH.png"
        className="yellow-splash-right-img"
        placeholder="none"
        alt="yellow graffiti splash"
      />
    )}
    {/* PINK GRAFFITI SQUIGGLE */}
    {bgElements?.pinkSquiggleRight && (
      <StaticImage
        src="../../images/bg_assets/PINK-SQUIGGLE.png"
        className="pink-squiggle-right-img"
        placeholder="none"
        alt="pink graffiti squiggle"
      />
    )}
    {/* PINK GRAFFITI SQUIGGLE */}
    {bgElements?.greyGraffitiBottom && (
      <StaticImage
        src="../../images/bg_assets/GREY-YELLOW-GRAFFITI.png"
        className="grey-graffiti-bottom-img"
        placeholder="none"
        alt="Yellow and grey graffiti squiggle"
        imgStyle={{ willChange: 'auto' }}
      />
    )}
    {/* YELLOW GRAFIITI WORDS */}
    {bgElements?.yellowGraffitiWords && (
      <StaticImage
        src="../../images/bg_assets/YELLOW-WRITING.png"
        className="yellow-words-img"
        placeholder="none"
        alt="Yellow graffiti word"
      />
    )}
    {/* WRITING TOP LEFT */}
    {bgElements?.filmWritingLeft && (
      <StaticImage
        src="../../images/bg_assets/FILM-WRITING-VERTICAL.png"
        className="film-writing-left-img"
        placeholder="none"
        alt="Film writing example"
      />
    )}
    {/* BLACK PINK GRAFFITI BOTTOM */}
    {bgElements?.blackPinkGraf && (
      <StaticImage
        src="../../images/bg_assets/GRAFF-BLACK.png"
        className="graffiti-black-pink-img"
        placeholder="none"
        alt="Film writing example"
      />
    )}
  </>
);
export default BackgroundElements;
