// extracted by mini-css-extract-plugin
export var section = "styles-module--section--1-5B0";
export var leftImage = "styles-module--leftImage--35fKM";
export var rightImage = "styles-module--rightImage--29Go6";
export var leftLogoAlign = "styles-module--leftLogoAlign--3l0XU";
export var rightLogoAlign = "styles-module--rightLogoAlign--2yY30";
export var container = "styles-module--container--3mAg-";
export var leftImageContainer = "styles-module--leftImageContainer--1bCpn";
export var rightImageContainer = "styles-module--rightImageContainer--2p9n2";
export var leftLogo = "styles-module--leftLogo--2bwbH";
export var rightLogo = "styles-module--rightLogo--VJ0Xx";
export var ctaRight = "styles-module--ctaRight--UiYgk";
export var ctaLeft = "styles-module--ctaLeft--3YTZi";
export var cta = "styles-module--cta--bHEml";
export var ctaText = "styles-module--ctaText--1ep31";
export var leftHover = "styles-module--leftHover--3BryS";
export var leftLogoMain = "styles-module--leftLogoMain--wOPea";
export var leftLogoAfter = "styles-module--leftLogoAfter--3Dl_-";
export var rightLogoMain = "styles-module--rightLogoMain--9ie0M";
export var glitch1 = "styles-module--glitch1--1O95I";
export var rightLogoBlue = "styles-module--rightLogoBlue--1POIy";
export var glitch2 = "styles-module--glitch2--3uzx_";
export var rightLogoPink = "styles-module--rightLogoPink--2EaTs";
export var rightLogoGreen = "styles-module--rightLogoGreen--3I3nB";
export var glitch3 = "styles-module--glitch3--YzZ4l";
export var rightHover = "styles-module--rightHover--1U5Qn";
export var pulse2 = "styles-module--pulse2--28JmG";
export var pulse = "styles-module--pulse--gZDfQ";