import React from 'react';
import { Image } from 'components';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from '../styles.module.scss';

const ImageQuarterVertical = ({ firstSlice, isProject, image }) => (
  <div className={styles.quarterWidth}>
    <div className={styles.imageEffectContainerTop}>
      {firstSlice && isProject && (
        <StaticImage
          alt="pink paint spray"
          placeholder="NONE"
          src="../../../images/spray-accent.png"
          style={{ height: '50em', width: '60em' }}
        />
      )}
    </div>
    <Image image={image} className="grid" />
  </div>
);

export default ImageQuarterVertical;
