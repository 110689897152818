import React from 'react';
import { Section } from 'components';
import { useInView } from 'react-intersection-observer';
import { Template } from './components';
import * as styles from './styles.module.scss';

const ImageText = (props) => {
  const { data, isProjectGallery } = props;
  const { primary } = data;
  const { border_top: borderTop, border_bottom: borderBottom } = primary;

  const [ref, inView] = useInView({
    threshold: 0,
    delay: 0,
    triggerOnce: true,
  });

  return (
    <Section
      containerClassName={inView ? styles.container : styles.containerHidden}
      sliceName="ImageText"
      className={`${borderTop ? styles.hasBorderTop : ''} ${borderBottom ? styles.hasBorderBottom : ''} ${
        styles.sectionSmaller
      } ${isProjectGallery ? styles.isProjGallery : ''}`}
    >
      <div className={styles.borderBox} ref={ref}>
        <Template primary={primary} isProjectGallery={isProjectGallery} debug />
      </div>
    </Section>
  );
};
export default ImageText;
