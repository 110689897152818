import React from 'react';
import * as styles from '../styles.module.scss';

const TextColumn = ({ props }) => {
  const { professionals, categories, ctaLink, ctaText, title, tag } = props;

  const directors = professionals?.text?.split('/');

  return (
    <div className={styles.rowTest}>
      <div className={styles.columnText}>
        <div className={styles.tagWrapper}>
          <p className={styles.tag}>{tag?.text}</p>
        </div>
        <p className={styles.title}>{title?.text}</p>
        <div className={styles.professionsRow}>
          {directors?.map((item, index) => (
            <div className={styles.professionItem}>
              <p className={styles.professionItemText}>{item}</p>
              {index !== directors?.length - 1 && <p className={styles.slash}> / </p>}
            </div>
          ))}
        </div>
        <div className={styles.row}>
          <div className={styles.categories}>
            <p className={styles.categoriesText}>{categories?.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextColumn;
