import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { OnVisible, Image, Cta } from 'components';
import { COLOR_BARS } from '../../../constants/constants';
import { PlayButton } from './index';
import * as styles from '../styles.module.scss';

const VideoTop = ({ videoProps, muteButton, isFilms }) => {
  const {
    primary,
    videoState,
    setVideoState,
    setVideoVisible,
    videoVisible,
    setVideoLoaded,
    isProject,
    isLandingFilms,
  } = videoProps;
  const { image, has_play_button: hasPlayButton, video_link: videoLink } = primary;
  const hasImage = image?.url;
  const hasVideo = videoLink?.url;

  const [muted, setMuted] = useState(true);

  return (
    <>
      <OnVisible className={styles.imageContainer} onChange={setVideoVisible}>
        {hasImage && <Image image={image} />}
        {videoVisible && (
          <ReactPlayer
            className={styles.player}
            url={videoLink?.url}
            playing={videoState}
            playsinline
            volume={1}
            muted={muted}
            onReady={() => setVideoLoaded(true)}
            config={{
              file: {
                attributes: {
                  crossOrigin: 'anonymous',
                },
              },
              vimeo: {
                playerOptions: { loop: true, autoplay: true, muted },
              },
            }}
            width="100%"
            height="100%"
          />
        )}
        {hasVideo && (
          <div className={isProject ? styles.barContainerProject : styles.barContainer}>
            {COLOR_BARS.map((color, index) => (
              <div className={styles.colorBar} style={{ backgroundColor: color }} key={`${index + 1}`} />
            ))}
          </div>
        )}
        {!isProject && hasVideo && muteButton && (
          <a onClick={() => setMuted(!muted)} className={styles.muteContainer}>
            <h4 className={isFilms ? styles.muteButtonFilms : styles.muteButton}>{muted ? 'UNMUTE' : 'MUTE'}</h4>
          </a>
        )}
      </OnVisible>
    </>
  );
};

export default VideoTop;
