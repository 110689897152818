import React from 'react';
import { Cta, Image } from 'components';
import * as styles from '../styles.module.scss';

const TitleLeft = ({ primary, isProjectGallery }) => {
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    image,
    secondary_cta_text: secondaryCtaText,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_alignment: secondaryCtaAlignment,
    name_credit: nameCredit,
    client_credit: companyCredit,
  } = primary;
  const titleHtml = title?.html;
  const hasCta = ctaText?.text;
  const hasSecondaryCta = secondaryCtaText?.text;

  const isNoLink = !!secondaryCtaText?.url;

  return (
    <div className={styles.titleLeftVariant}>
      {hasSecondaryCta && secondaryCtaAlignment === 'Top' && (
        <div className={styles.ctaSecondaryRowTop}>
          <Cta text={secondaryCtaText?.text} url={secondaryCtaLink?.url} isPinkArrow isNoLink />
        </div>
      )}

      <div className={isProjectGallery ? styles.rowLeftVariationGallery : styles.rowLeftVariation}>
        <div className={styles.titleCtaColumn}>
          <div className={styles.titleColumnLeft}>
            {titleHtml && (
              <div
                dangerouslySetInnerHTML={{ __html: titleHtml }}
                className={isProjectGallery ? styles.galleryTitleLeft : styles.titleLeftVariation}
              />
            )}
            {(nameCredit?.text || companyCredit?.text) && (
              <div className={styles.creditsContainer}>
                <span className={styles.nameCredit}>{nameCredit.text}</span>
                <span className={styles.companyCredit}>{companyCredit.text}</span>
              </div>
            )}
          </div>
          {hasCta && (
            <div className={styles.ctaLeftVariation}>
              <Cta text={ctaText?.text} url={ctaLink?.url} />
            </div>
          )}
        </div>
        <div className={styles.imageContainer}>
          <Image image={image} useDistortion />
        </div>
      </div>

      {hasSecondaryCta && secondaryCtaAlignment === 'Bottom' && (
        <div className={styles.ctaSecondaryRow}>
          <Cta text={secondaryCtaText?.text} url={secondaryCtaLink?.url} isPinkArrow isNoLink={isNoLink} />
        </div>
      )}
    </div>
  );
};

export default TitleLeft;
