// extracted by mini-css-extract-plugin
export var itemsContainer = "styles-module--itemsContainer--3aI2i";
export var cta = "styles-module--cta--2PwR2";
export var item = "styles-module--item--OSlcI";
export var imageAndText = "styles-module--imageAndText--34naF";
export var image = "styles-module--image--2vVaJ";
export var textContainer = "styles-module--textContainer--11Ngu";
export var videoStrip = "styles-module--videoStrip--3LZnj";
export var videoItem = "styles-module--videoItem--1G-TD";
export var videoItemVideo = "styles-module--videoItemVideo--3aZBb";
export var textContent = "styles-module--textContent--311Pi";
export var jobType = "styles-module--jobType--38A0p";
export var client = "styles-module--client--1fztS";
export var containerGalleryBox = "styles-module--containerGalleryBox--3c8OM";
export var playButtonContainer = "styles-module--playButtonContainer--3HaP-";
export var smallPlayButton = "styles-module--smallPlayButton--__TRG";
export var title = "styles-module--title--2zwXc";
export var subtitle = "styles-module--subtitle--1Xmkp";
export var content = "styles-module--content--CTThz";