// extracted by mini-css-extract-plugin
export var containerClass = "styles-module--containerClass--34E3K";
export var row = "styles-module--row--2jc53";
export var column = "styles-module--column--2zRgV";
export var industries = "styles-module--industries--TzahP";
export var creditsContainer = "styles-module--creditsContainer--1yJKf";
export var nameCredit = "styles-module--nameCredit--1CkN-";
export var companyCredit = "styles-module--companyCredit--1sUxO";
export var agencyCredit = "styles-module--agencyCredit--2qoll";
export var imageWrapper = "styles-module--imageWrapper--POFnD";
export var industriesText = "styles-module--industriesText--1okIh";
export var industry = "styles-module--industry--3-Erw";
export var slash = "styles-module--slash--1MMtT";
export var cta = "styles-module--cta--2fJHZ";
export var columnVisible = "styles-module--columnVisible--djuux";
export var loadMoreGoodness = "styles-module--loadMoreGoodness--3A1S1";
export var noProjectsFound = "styles-module--noProjectsFound--1SRjG";
export var visible = "styles-module--visible--1HViM";
export var invisible = "styles-module--invisible--1tcxV";
export var textContent = "styles-module--textContent--39EjZ";
export var projectTitle = "styles-module--projectTitle--24HQH";
export var hoverStateContainer = "styles-module--hoverStateContainer--Pe9Vu";