import React, { Fragment } from 'react';
import { Section, Link, Image, Cta } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { ListItem } from './components';
import { colorBarsGreyScale } from '../../constants/constants';

import * as styles from './styles.module.scss';

const TwoColsTextList = (props) => {
  const threeRecentPosts = useStaticQuery(query);

  const { data } = props;
  const { primary } = data;
  const { allPrismicBlogPost } = threeRecentPosts;

  const items = allPrismicBlogPost?.nodes;

  const {
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_text: secondaryCtaText,
    image_effect: imageEffect,
  } = primary;

  const hasCta = ctaText?.text;

  const Template = () => (
    <>
      <div className={styles.ctaContainer}>
        <Cta text={secondaryCtaText.text} url={secondaryCtaLink.url} isPinkArrow darkText isNoLink />
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          {content.html && <div dangerouslySetInnerHTML={{ __html: content.html }} className={styles.contentText} />}
        </div>
        <div className={styles.columnRight}>
          {items.map((_item, index) => {
            const { data: _data } = _item;
            const [first] = _data?.body;
            const { title } = first?.primary;

            const item = {
              content: _data?.description,
              date: _data?.date || _item?.last_publication_date,
              title,
              cta_text: { text: 'CHECK IT OUT' },
              cta_link: { url: _item?.url },
            };

            return <ListItem key={item?.title?.text} item={item} firstItem={index === 0} />;
          })}
          {hasCta && (
            <div className={styles.buttonRow}>
              <Link to={ctaLink?.url}>
                <button type="submit" className={styles.mainCta}>
                  {ctaText?.text}
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );

  return (
    <Section containerClassName={styles.container} className={styles.containerClass} sliceName="TwoColsTextList">
      <div className={styles.wrapped}>
        <Template />
        {imageEffect?.url && (
          <div className={styles.imageEffectContainerLeft}>
            <Image image={imageEffect} />
          </div>
        )}
      </div>
    </Section>
  );
};
export default TwoColsTextList;

const query = graphql`
  query MyQuery {
    allPrismicBlogPost(limit: 3, sort: { fields: data___date, order: DESC }) {
      nodes {
        data {
          date
          description
          body {
            ... on PrismicBlogPostBodyLargeImageText {
              id
              primary {
                title {
                  html
                  text
                }
              }
            }
          }
        }
        url
        last_publication_date
      }
    }
  }
`;
