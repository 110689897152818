// extracted by mini-css-extract-plugin
export var sectionSmaller = "styles-module--sectionSmaller--2cQKK";
export var isProjGallery = "styles-module--isProjGallery--2lNCQ";
export var container = "styles-module--container--1Wo0w";
export var hasBorderTop = "styles-module--hasBorderTop--1NQH3";
export var borderBox = "styles-module--borderBox--1W93y";
export var hasBorderBottom = "styles-module--hasBorderBottom--uD4Fd";
export var containerHidden = "styles-module--containerHidden--I8vfP";
export var rowGallery = "styles-module--rowGallery--2tVMF";
export var row = "styles-module--row--1rTXv";
export var rowLeftVariation = "styles-module--rowLeftVariation--4Yug2";
export var rowLeftVariationGallery = "styles-module--rowLeftVariationGallery--3R5bV";
export var rowCenterVariant = "styles-module--rowCenterVariant--2L43R";
export var titleCenterVariant = "styles-module--titleCenterVariant--1AtqA";
export var titleCenterGallery = "styles-module--titleCenterGallery--1zghQ";
export var titleWraper = "styles-module--titleWraper--GnVGX";
export var ctaSecondaryRow = "styles-module--ctaSecondaryRow--38EjC";
export var ctaLeftVariation = "styles-module--ctaLeftVariation--3IXjC";
export var ctaSecondaryRowTop = "styles-module--ctaSecondaryRowTop--1oT3T";
export var ctaRow = "styles-module--ctaRow--2vpvh";
export var ctaRowBottomVariant = "styles-module--ctaRowBottomVariant--2LECw";
export var titleCTARow = "styles-module--titleCTARow--2rkut";
export var titleCtaRowGallery = "styles-module--titleCtaRowGallery--9z1LC";
export var titleCtaColumn = "styles-module--titleCtaColumn--3UoWU";
export var column = "styles-module--column--3wbiF";
export var containerColumn = "styles-module--containerColumn--3COC4";
export var imageContainer = "styles-module--imageContainer--1NYds";
export var imageContainerLeft = "styles-module--imageContainerLeft--1McYn";
export var imageContainerCenter = "styles-module--imageContainerCenter--1tkub";
export var titleGallery = "styles-module--titleGallery--3k7C6";
export var arrowRight = "styles-module--arrowRight--2k_ix";
export var galleryTitleLeft = "styles-module--galleryTitleLeft--1j7Yb";
export var title = "styles-module--title--2_RR5";
export var creditsContainer = "styles-module--creditsContainer--1abv8";
export var nameCredit = "styles-module--nameCredit--137Xz";
export var companyCredit = "styles-module--companyCredit--2O8QC";
export var titleColumnLeft = "styles-module--titleColumnLeft--1uko0";
export var titleColumnCenter = "styles-module--titleColumnCenter--2d2g1";