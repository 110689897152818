import React from 'react';
import { Section, Cta } from 'components';
import { ImageColumn, TextColumn } from './components';
import * as styles from './styles.module.scss';

const Showcase = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    image,
    categories,
    cta_link: ctaLink,
    cta_text: ctaText,
    professionals,
    slice_alignment: isRightALignment,
    two_columns_style: isTwoColumns,
    title,
    tag,
  } = primary;

  const textColumnProps = { professionals, categories, ctaLink, ctaText, title, tag };

  const Template = () => {
    if (isTwoColumns) {
      return (
        <>
          {isRightALignment ? (
            <>
              <div className={styles.row}>
                <div className={styles.column}>
                  <TextColumn props={textColumnProps} />
                  <div className={styles.ctaContainer}>
                    <Cta text={ctaText?.text} url={ctaLink?.url} />
                  </div>
                </div>
                <div className={styles.column}>
                  <ImageColumn image={image} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.row}>
                <ImageColumn image={image} />
                <div className={styles.columnRight}>
                  <TextColumn props={textColumnProps} />
                  <div className={styles.ctaContainer}>
                    <Cta text={ctaText?.text} url={ctaLink?.url} />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    return (
      <>
        <div className={isRightALignment ? styles.rowRight : styles.rowLeft}>
          <div className={styles.rowWrapper}>
            <ImageColumn image={image} />
            <TextColumn props={textColumnProps} />
          </div>
        </div>
      </>
    );
  };

  return (
    <Section containerClassName={styles.container} className={styles.containerClass} sliceName="Showcase">
      <div className={styles.wrapped}>
        <Template />
      </div>
    </Section>
  );
};
export default Showcase;
