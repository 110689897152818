import React, { useState, useEffect, useRef } from 'react';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { OnVisible } from 'components';
import useImageHoverEffect from '../../hooks/useImageHoverEffect';
import './styles.scss';

const Image = (props) => {
  const imageRef = useRef();
  const [loaded, setLoaded] = useState(false);
  const [imageEl, setImageEl] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { image, alt, className = '', imgStyle, useDistortion, ...otherProps } = props;

  const imageData = getImage(image?.localFile);

  useEffect(() => {
    const mobileCheck =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      );
    setIsMobile(mobileCheck);
  }, []);

  useEffect(() => {
    if (imageRef?.current) {
      setLoaded(true);
      setImageEl(imageRef?.current);
    }
  }, [imageRef?.current]);

  useImageHoverEffect(imageEl, loaded, useDistortion, isMobile);

  if (!image) return <div className={`gatsby-image placeholder ${className}`} />;

  if (imageData && (!useDistortion || (useDistortion && isMobile))) {
    return (
      <GatsbyImage
        className={`gatsby-image ${className}`}
        image={imageData}
        alt={image.alt || alt || ''}
        imgStyle={imgStyle}
        loading="lazy"
        {...otherProps}
      />
    );
  }

  if (image.url) {
    return (
      <OnVisible
        triggerOnce={false}
        threshold={0.5}
        className={`gatsby-image ${useDistortion ? 'use-distortion' : ''} ${className}`}
      >
        <img src={image.url} alt={image.alt || alt || ''} style={imgStyle} ref={imageRef} />
      </OnVisible>
    );
  }

  return <div className={`gatsby-image placeholder ${className}`} />;
};

export default Image;
