// extracted by mini-css-extract-plugin
export var containerClass = "styles-module--containerClass--1fk5Y";
export var row = "styles-module--row--3GiMk";
export var column = "styles-module--column--H3Zl_";
export var columnAgency = "styles-module--columnAgency--3U9_d";
export var columnItems = "styles-module--columnItems--2I7Pl";
export var columnItemsAgency = "styles-module--columnItemsAgency--229lL";
export var wrapped = "styles-module--wrapped--1qCH6";
export var item = "styles-module--item--2iTB_";
export var slashItems = "styles-module--slashItems--3dfxD";
export var value = "styles-module--value--qubLw";
export var slash = "styles-module--slash--1XkFU";
export var itemTitle = "styles-module--itemTitle--2Muve";
export var recognitionContainer = "styles-module--recognitionContainer--TX8of";
export var mainCta = "styles-module--mainCta--22X0Q";
export var subTitle = "styles-module--subTitle--IpQEA";
export var description = "styles-module--description--8D9Kn";