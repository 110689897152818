import React, { useState } from 'react';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox-pro';
import Link from '../Link';
import Scrambler from '../Scrambler';
import OnVisible from '../OnVisible';
import { ReactComponent as CtaArrowRight } from '../../images/icons/arrow-right.svg';
import { ReactComponent as CtaArrowRightPink } from '../../images/icons/arrow-right-pink.svg';
import { ReactComponent as CtaArrowRightOffWhite } from '../../images/icons/arrow-right-offWhite.svg';

import * as styles from './styles.module.scss';

const Cta = ({
  text,
  url,
  isPinkArrow = false,
  removePadding,
  darkText = false,
  isOffWhiteArrow = false,
  isNoLink,
  video,
  isLightBox,
  className,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const lightboxOptions = {
    settings: {
      autoplaySpeed: 3000,
      boxShadow: 'none',
      disableKeyboardControls: false,
      disablePanzoom: false,
      disableWheelControls: false,
      hideControlsAfter: 3000,
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: 'linear',
      overlayColor: 'rgba(9, 9, 9, 0.9)',
      slideAnimationType: 'fade',
      slideSpringValues: [300, 50],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: 'linear',
      usingPreact: false,
    },
    buttons: {
      backgroundColor: 'rgba(9, 9, 9, 0.9)',
      iconColor: '#e81f76',
      iconPadding: '10px',
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: true,
      size: '40px',
    },
    thumbnails: {
      showThumbnails: false,
      thumbnailsAlignment: 'center',
      thumbnailsContainerBackgroundColor: 'transparent',
      thumbnailsContainerPadding: '0',
      thumbnailsGap: '0 5px',
      thumbnailsIconColor: '#ffffff',
      thumbnailsOpacity: 0.4,
      thumbnailsPosition: 'bottom',
      thumbnailsSize: ['100px', '80px'],
    },
  };

  const GalleryBox = () => {
    const { openLightbox } = useLightbox();
    return (
      <>
        <div className="grid" onClick={openLightbox}>
          <p className={styles.cta}>
            <Scrambler text={text} visible={isVisible} />
          </p>
        </div>
        <SRLWrapper options={lightboxOptions}>
          <div className="imageContainerLightBox">
            <div>
              <a srl_video_loop="true" srl_video_scale="90" href={url}>
                <img
                  src="data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABgcA/8QAFQEBAQAAAAAAAAAAAAAAAAAABQD/2gAMAwEAAhADEAAAAYuikL0+SaZZEH//xAAcEAACAgIDAAAAAAAAAAAAAAACBAEDAAUREhT/2gAIAQEAAQUC1nFqrjpNa30h0EpjECkl3LjBn//EABwRAAEEAwEAAAAAAAAAAAAAAAEAAhEhAwQFEv/aAAgBAwEBPwHZLcjR4bFI88m5X//EABoRAAICAwAAAAAAAAAAAAAAAAABERITFCH/2gAIAQIBAT8B1lksJxw//8QAHxAAAgICAQUAAAAAAAAAAAAAAQIAAxEhEhMxQXKR/9oACAEBAAY/ArLOpxKZwPkqW1iStjdzE9ZoyvO9eZYAxABn/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIVFB/9oACAEBAAE/IS5paJy0CECaZlVMB8EB0ILOt+oh4oE//9oADAMBAAIAAwAAABDUz//EABsRAQACAgMAAAAAAAAAAAAAAAEAESExQWFx/9oACAEDAQE/EA+yZN7Rc9cRW2/s/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARQVH/2gAIAQIBAT8QSFt5FNMn/8QAHBABAQADAAMBAAAAAAAAAAAAAREAITFBUWGB/9oACAEBAAE/EL2dixF0+y/mHdrWBcqXQEwOIoVnzHwCpQe5v7VAbXu/OBVEbw9Z/9k="
                  alt="Play Video"
                  style={{ display: 'none' }}
                />
              </a>
            </div>
          </div>
        </SRLWrapper>
      </>
    );
  };

  return (
    <OnVisible
      as="div"
      onChange={setIsVisible}
      className={`${isNoLink ? styles.ctaRowNoLink : styles.ctaRow} cta-container ${className || ''}`}
      style={{ padding: removePadding && 0 }}
    >
      {!isNoLink && !isLightBox && (
        <Link to={url} className={darkText ? styles.ctaDark : styles.cta}>
          <Scrambler text={text} visible={isVisible} />
        </Link>
      )}

      {isNoLink && !isLightBox && (
        <p className={darkText ? styles.ctaDark : styles.cta}>
          <Scrambler text={text} visible={isVisible} />
        </p>
      )}

      {isLightBox && (
        <SimpleReactLightbox>
          <GalleryBox />
        </SimpleReactLightbox>
      )}

      <div className={`${className || ''} ${styles.ctaArrow}`} style={{ padding: removePadding && 0 }}>
        <div className={styles.ctaContainer}>
          {!isPinkArrow && !isOffWhiteArrow && <CtaArrowRight className={styles.arrowRight} />}
          {isPinkArrow && <CtaArrowRightPink className={styles.arrowRight} />}
          {isOffWhiteArrow && <CtaArrowRightOffWhite className={styles.arrowRight} />}
        </div>
      </div>
    </OnVisible>
  );
};

export default Cta;
