/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect, Fragment } from 'react';
import { animate, glide } from 'motion';
import { use100vh } from 'react-div-100vh';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Link, Hamburger } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { ReactComponent as Logo } from '../../images/icons/logo.svg';
import { ReactComponent as ArrowUpRight } from '../../images/icons/arrow-up-right.svg';
import paintSpray from '../../images/paint-spray.webp';
import paintSpraySecond from '../../images/paint-spray-secondary.webp';
import paintSprayThird from '../../images/paint-spray-third.webp';
import heartGraffiti from '../../images/heart-graffiti.webp';
import exGraff from '../../images/exGraff.webp';
import { ReactComponent as LogoFilms } from '../../images/icons/logo-films.svg';
import { ReactComponent as LogoFilmsDark } from '../../images/icons/films-logo-dark.svg';

import * as styles from './styles.module.scss';

const Header = ({ slug }) => {
  const site = process.env.GATSBY_SITE_NAME;
  const isFilms = site === 'films';
  const isLanding = process.env.GATSBY_IS_LANDING;

  const whiteHeader = isFilms && !slug?.includes('project');

  // data
  const links = useStaticQuery(header);
  const headerLinks = isFilms ? links?.prismicSettings?.data?.links_films : links?.prismicSettings?.data?.links;

  // scroll logic
  const [scrollPos, setScrollPos] = useState(null);
  const [upScroll, setUpScroll] = useState(0);

  const scrollListener = () => {
    const prevScrollPos = scrollPos;
    const newScrollPos = window.pageYOffset;
    if (newScrollPos < prevScrollPos || scrollPos < 100) {
      setUpScroll(true);
    }
    if (newScrollPos > prevScrollPos && scrollPos > 100) {
      setUpScroll(false);
    }
    setScrollPos(window.pageYOffset);
  };

  useEffect(() => {
    if (window.pageYOffset === 0) {
      setUpScroll(true);
    }
    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [scrollPos]);

  // scroll animate
  useEffect(() => {
    if (upScroll) {
      animate('#header', { opacity: 1 }, { duration: 0.5 });
      animate(
        '#header',
        { transform: `translate(0px, 0px)` },
        { duration: 0, easing: glide({ velocity: -1500, min: -800, bounceStiffness: 500, bounceDamping: 20 }) }
      );
    }
    if (!upScroll) {
      animate('#header', { opacity: 0 }, { duration: 1.8 });

      animate(
        '#header',
        { transform: `translate(0px, -120px)` },
        { duration: 0, easing: glide({ velocity: -1500, min: -800, bounceStiffness: 500, bounceDamping: 20 }) }
      );
    }
  }, [upScroll]);

  // anchor logic
  const scrollToForm = () => {
    const node = document.getElementById('scrollTo');

    scrollIntoView(node, {
      behavior: 'smooth',
      block: 'start',
      duration: 1000,
    });
  };

  // hamburger logic
  const [active, setActive] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [bodyLocked, setBodyLock] = useState(false);

  const onClick = (event) => {
    event.preventDefault();
    setActive(!active);
  };

  const handleEscKey = (event) => {
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setActive(false);
    }
  };

  const lockBody = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  useEffect(() => {
    if (active && !bodyLocked) lockBody();
    if (!active && bodyLocked) unlockBody();
  }, [active]);

  const classNames = ` ${styles.scrolledUp} ${isLanding ? styles.hideNav : ''} ${styles.headerContainer} ${
    styles.header
  } ${active ? styles.hamburgerOpen : ''} ${isFilms ? styles.headerIsFilms : styles.headerIsAgency}`;

  const paintSprayImages = isFilms
    ? [exGraff, paintSpray, heartGraffiti, paintSpraySecond]
    : [paintSpray, paintSpraySecond, paintSprayThird];

  const height = use100vh();

  // INLINE LINK COMP
  const HeaderItem = ({ item, className, index }) => {
    const linkText = item?.text?.text;
    const linkUrl = item?.link?.url;

    const slugCompare = slug?.split('/')?.join('');
    const linkUrlCompare = linkUrl?.split('/')?.join('');
    const isBlog = slug?.includes('blog') && linkUrlCompare?.includes('blog');

    const activeItem =
      linkUrlCompare === slugCompare || isBlog || (slug?.includes('project') && linkText === 'WORK WE DID');

    return (
      <div className={`${styles.item} ${className || ''} ${activeItem ? styles.activeItem : ''} `}>
        {index || index === 0 ? (
          <div className={styles.imageEffectContainerFirst}>
            <img
              src={paintSprayImages[index]}
              alt="paintSpray"
              className={isFilms ? styles.paintItemFilms : styles.paintItemFirst}
            />
          </div>
        ) : null}
        <Link className={`${activeItem ? styles.navLinkActive : ''} ${styles.navLink}`} to={`${linkUrl}`}>
          <span className={styles.buttonRightWhite} style={{ color: whiteHeader && 'black' }}>
            {linkText}
          </span>
        </Link>
      </div>
    );
  };

  return (
    <header
      id="header"
      className={classNames}
      style={{ backgroundColor: whiteHeader && !isLanding && 'white', height: active ? height : '' }}
    >
      <div className={whiteHeader && !isLanding ? styles.noiseWrapperWhite : styles.noiseWrapper}>
        <div className={styles.noise} />
      </div>
      <div className={`container ${styles.container}`}>
        {/* LOGO + HAMBURGER */}
        <div className={styles.logoHamburgerContainer}>
          <div className={styles.iconWrapperLogo}>
            {isFilms ? (
              <Link to="/">
                {whiteHeader && !isLanding ? (
                  <LogoFilmsDark className={styles.icon} />
                ) : (
                  <LogoFilms className={styles.icon} />
                )}
              </Link>
            ) : (
              <Link to="/">
                <Logo className={styles.icon} />
              </Link>
            )}
          </div>
          <div className={styles.hamburgerContainer}>
            <Hamburger onClick={onClick} active={active} className={styles.hamburger} />
          </div>
        </div>
        {/* LINKS */}
        {!isLanding && (
          <div className={styles.rowIcons}>
            {headerLinks?.map((item, index) => (
              <HeaderItem key={item?.text?.text} item={item} index={index} />
            ))}
            <HeaderItem
              item={{ text: { text: 'TALK TO US' }, link: { url: '/talk-to-us/' } }}
              className={styles.hamburgerItem}
            />
          </div>
        )}
        {/* CTA */}
        <div className={styles.navRightEnd} role="button" onClick={() => scrollToForm()} tabIndex={0}>
          <a className={styles.buttonRight}>TALK TO US</a>
          <div className={styles.arrowContainer}>
            <ArrowUpRight height={10} width={10} className={styles.arrowUpRight} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

export const header = graphql`
  query header {
    prismicSettings {
      data {
        links {
          link {
            url
          }
          text {
            text
          }
        }
        links_films {
          link {
            url
          }
          text {
            html
            text
          }
        }
      }
    }
  }
`;
