import React from 'react';
import { Section, Noise } from 'components';
import * as styles from './styles.module.scss';

const ProjectFilter = ({ setFilterValue, filterValue }) => {
  const page = process.env.GATSBY_SITE_NAME;
  const isFilms = page === 'films';

  const filters = {
    films: [
      'ALL',
      'CINEMATOGRAPHY',
      'PHOTOGRAPHY',
      'SCRIPTING',
      'DIRECTION',
      'EDITING',
      'PRODUCING',
      'COLOUR GRADE',
      'SOUND DESIGN',
      'MOTION GRAPHICS',
      'VISUAL EFFECTS',
    ],
    agency: [
      'ALL',
      'Advertising',
      'Strategy',
      'Concepting',
      'Branding',
      'Design',
      'Digital & Social',
      'Photography',
      'Print Production',
      'Film & TV Production',
      'Editing',
      'Motion Graphics',
      'Visual Effects',
    ],
  };

  return (
    <Section
      className={`${isFilms ? styles.filmVariant : styles.agencyVariant} ${styles.containerClass}`}
      containerClassName={styles.container}
      sliceName="ProjectFilter[comp]"
    >
      {isFilms && <Noise />}
      <div className={styles.filterRow}>
        {filters[page]?.map((service, index) => {
          const isActive = filterValue === service;

          return (
            <div className={styles.itemContainer} key={service}>
              <a
                onClick={() => {
                  setFilterValue(service);
                }}
                role="button"
                tabIndex={0}
                className={styles.button}
              >
                <p className={`${styles.filterItem} ${isActive ? styles.filterItemActive : ''}`}>
                  {service.toUpperCase()}
                </p>
              </a>
              {index !== filters[page]?.length - 1 && <p className={styles.slash}>/</p>}
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default ProjectFilter;
