import React from 'react';
import { Section, Link, Image, Cta } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { ImageColumn, TextColumn } from './components';
import * as styles from './styles.module.scss';

const CtaCols = (props) => {
  const partnersData = useStaticQuery(partnersQuery);
  const _props = props;

  const { cta_Link: primaryCtaLink, cta_text: primaryCtaText } = _props?.data?.primary;

  const { data } = partnersData?.prismicSettings;

  const {
    education,
    fashion_and_product: fashionProduct,
    health_and_community: healthCommunity,
    hospitality_and_tourism: hospitalityTourism,
    property_and_place: propertyPlace,
    resources,
    sport_and_recreation: sportRec,
  } = data;

  const Template = () => (
    <div className={styles.row}>
      <div className={styles.column}>
        <p className={styles.title}>Health & Community</p>
        {healthCommunity?.map((item) => (
          <p>{item?.cta_text?.text}</p>
        ))}
        <p className={styles.title}>Resources</p>

        {resources?.map((item) => (
          <p>{item?.cta_text?.text}</p>
        ))}
      </div>
      <div className={styles.column}>
        <p className={styles.title}>Sports Rec</p>
        {sportRec?.map((item) => (
          <p>{item?.cta_text?.text}</p>
        ))}

        <p className={styles.title}>Education</p>
        {education?.map((item) => (
          <p>{item?.cta_text?.text}</p>
        ))}
      </div>
      <div className={styles.column}>
        <p className={styles.title}>Property & Place</p>
        {propertyPlace?.map((item) => (
          <p>{item?.cta_text?.text}</p>
        ))}
      </div>
      <div className={styles.column}>
        <p className={styles.title}>Hospitality & Tourism</p>
        {hospitalityTourism?.map((item) => (
          <p>{item?.cta_text?.text}</p>
        ))}
        <p className={styles.title}>Fashion & Product</p>
        {fashionProduct?.map((item) => (
          <p>{item?.cta_text?.text}</p>
        ))}
      </div>
    </div>
  );

  return (
    <Section containerClassName={styles.container} className={styles.containerClass} sliceName="CtaCols">
      <div className={styles.wrapped}>
        <div className={styles.ctaContainer}>
          <Cta text={primaryCtaText?.text} url={primaryCtaLink?.url} isPinkArrow isNoLink />
        </div>
        <Template />
      </div>
    </Section>
  );
};
export default CtaCols;

export const partnersQuery = graphql`
  query settingsPartner {
    prismicSettings {
      data {
        education {
          cta_link {
            url
          }
          cta_text {
            text
          }
        }
        fashion_and_product {
          cta_link {
            url
          }
          cta_text {
            text
          }
        }
        health_and_community {
          cta_link {
            url
          }
          cta_text {
            text
          }
        }
        hospitality_and_tourism {
          cta_link {
            url
          }
          cta_text {
            text
          }
        }
        property_and_place {
          cta_link {
            url
          }
          cta_text {
            text
          }
        }
        resources {
          cta_link {
            url
          }
          cta_text {
            text
          }
        }
        sport_and_recreation {
          cta_link {
            url
          }
          cta_text {
            text
          }
        }
      }
    }
  }
`;
