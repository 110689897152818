// extracted by mini-css-extract-plugin
export var row = "styles-module--row--2LcCN";
export var ctaContainer = "styles-module--ctaContainer--1TqNL";
export var rowReverse = "styles-module--rowReverse--iHiU0";
export var rowRight = "styles-module--rowRight--1umip";
export var rowLeft = "styles-module--rowLeft--_dint";
export var rowWrapper = "styles-module--rowWrapper--1uH1f";
export var rowTest = "styles-module--rowTest--3Qa3G";
export var column = "styles-module--column--f6-Vl";
export var mainCta = "styles-module--mainCta--3BU7y";
export var imageContainer = "styles-module--imageContainer--1PriO";
export var categories = "styles-module--categories--gkyI9";
export var professionsRow = "styles-module--professionsRow--12QWN";
export var professionItem = "styles-module--professionItem--vBWXD";
export var professionItemText = "styles-module--professionItemText--3sHwb";
export var tag = "styles-module--tag--tXAEl";
export var title = "styles-module--title--1jqKJ";
export var slash = "styles-module--slash--gepSx";
export var arrowRight = "styles-module--arrowRight--24WzM";
export var ctaRow = "styles-module--ctaRow--36bFR";
export var categoriesRow = "styles-module--categoriesRow--15Vu3";
export var wrapped = "styles-module--wrapped--22wC7";
export var tagWrapper = "styles-module--tagWrapper--fgepY";