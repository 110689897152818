/* eslint-disable array-callback-return */
import React, { useState, Fragment } from 'react';
import { useInView } from 'react-intersection-observer';
import { Section, Image, Link, Cta } from 'components';
import siteServices from '../../helpers/siteServices';
import ImageText from '../ImageText';

import * as styles from './styles.module.scss';

const TwoColsProjectGallery = (props) => {
  const amountToShow = 9;

  const { data, filterValue, isFilms } = props;
  const { items } = data;
  const [seeMore, setSeeMore] = useState(amountToShow);
  const page = process.env.GATSBY_SITE_NAME;

  const filteredItems = items
    ?.filter((item) => {
      const values = item?.data?.services?.map((service) => service?.service_select?.toLowerCase());
      const belongsTo = item?.data?.page_type?.toLowerCase();
      const filterParam = values?.includes(filterValue.toLowerCase());
      const projectBelongsToSiteType = belongsTo === page || belongsTo === 'both';

      if (filterParam && projectBelongsToSiteType) {
        return item;
      }
      if (filterValue === 'ALL' && projectBelongsToSiteType) {
        return item;
      }
    })
    ?.slice(0, seeMore);

  const Item = ({ item, index }) => {
    const [ref, inView] = useInView({
      threshold: 0,
      delay: 0,
      triggerOnce: true,
    });

    const [refSecondary, inViewSecondary] = useInView({
      threshold: 0,
      delay: 0,
      triggerOnce: true,
    });

    // featured projects
    const firstFeatureImage = index === 4;
    const lastFeatureImage = index === 9;
    const { project_title: projectTitle, placeholder_image: placeholderImage, data: galleryData, url } = item;

    const {
      title: galleryTitle,
      video_link: galleryVideoLink,
      video_image: image,
      client,
      agency_client: agencyClient,
      director,
      industries,
      services,
      video_title: videoTitle,
      page_type: pageType,
    } = galleryData || {};

    const ImageTextSliceProps = {
      primary: {
        cta_link: { url },
        cta_text: { text: 'VIEW PROJECT' },
        title: videoTitle,
        title_alignment: firstFeatureImage ? 'Left' : '',
        video_link: galleryVideoLink,
        image,
      },
    };

    const hasIndustries = industries.length > 0;

    let servicesToUse = [];

    // IF NO SELECT SERVICE
    const plainTextServices = services.map((obj) => obj.service);

    if (!services[0]?.service_select) {
      servicesToUse = plainTextServices;
    }

    // IF BOTH
    if (pageType === 'Both') {
      if (isFilms) {
        services.forEach((obj) => {
          if (siteServices.films.includes(obj?.service_select)) {
            servicesToUse.push(obj?.service_select);
          }
        });
      } else {
        services.forEach((obj) => {
          if (siteServices.agency.includes(obj?.service_select)) {
            servicesToUse.push(obj?.service_select);
          }
        });
      }
    } else {
      const allSelectServices = services.map((obj) => obj.service_select);
      servicesToUse = allSelectServices;
    }

    return (
      <>
        {firstFeatureImage || lastFeatureImage ? (
          <div ref={refSecondary} className={inViewSecondary ? styles.visible : styles.invisible}>
            <ImageText data={ImageTextSliceProps} isProjectGallery />
          </div>
        ) : (
          <div
            className={`${styles.column} ${inView ? styles.columnVisible : ''} ${
              hasIndustries ? styles.hasIndustries : ''
            }`}
            ref={ref}
          >
            <Link to={url}>
              <div className={styles.imageWrapper}>
                {/* INDUSTRY LIST HOVER */}
                <div className={styles.industries}>
                  <div className={styles.industriesText}>
                    {servicesToUse.map((serviceText, colIndex) => {
                      if (!serviceText) return null;
                      return (
                        <Fragment key={serviceText}>
                          <span className={styles.industry}>{`${serviceText.toLowerCase().replace('tv', 'TV')}`}</span>
                          <span className={styles.slash}>{`${colIndex !== servicesToUse.length - 1 ? '/' : ''}`}</span>
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
                {/* IMAGE */}
                {image && <Image className={styles.image} image={placeholderImage || image} />}
              </div>
              <div className={styles.textContent}>
                {/* TITLE */}
                <span className={styles.projectTitle}>{projectTitle?.text || galleryTitle?.text}</span>
                {/* CREDITS */}
                {isFilms ? (
                  <>
                    {(director?.text || client?.text) && (
                      <div className={styles.creditsContainer}>
                        {director?.text && <span className={styles.nameCredit}>{director.text}</span>}
                        {client?.text && <span className={styles.companyCredit}>{client?.text}</span>}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {agencyClient?.text && (
                      <div className={styles.creditsContainer}>
                        {client?.text && <span className={styles.agencyCredit}>{agencyClient?.text}</span>}
                      </div>
                    )}
                  </>
                )}
              </div>
            </Link>
          </div>
        )}
      </>
    );
  };

  const Template = () => (
    <>
      <div className={styles.row}>
        {filteredItems.length !== 0 ? (
          <>
            {filteredItems?.map((item, index) => (
              <Fragment key={`item-${index}`}>
                <Item item={item} index={index} />
              </Fragment>
            ))}
          </>
        ) : (
          <div className={styles.noProjectsFound}>
            <h4>No projects found with the selected filter :(</h4>
          </div>
        )}
      </div>
    </>
  );

  return (
    <Section containerClassName={styles.container} className={styles.containerClass} sliceName="TwoColsProjectGallery">
      <Template />
      <>
        {items.length > 9 && seeMore === amountToShow && filteredItems.length >= 9 && (
          <div className={styles.loadMoreGoodness}>
            <a onClick={() => setSeeMore(items?.length)} role="button" tabIndex={0}>
              <Cta text="LOAD MORE GOODNESS" isNoLink isPinkArrow />
            </a>
          </div>
        )}
      </>
    </Section>
  );
};
export default TwoColsProjectGallery;
