import React, { Fragment } from 'react';
import { Section, Image, Cta, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const TwoColsTextImage = (props) => {
  const { data } = props;
  const { items, primary } = data;
  const { title } = primary;
  const hasTitle = title?.text;

  return (
    <Section sliceName="TwoColsTextImage" containerClassName={styles.container}>
      {hasTitle && <Cta isPinkArrow isNoLink text={hasTitle} className={styles.cta} />}
      <div className={styles.itemsContainer}>
        {items.map((item) => {
          const { title: cardTitle, subtitle, image, content } = item;
          return (
            <Fragment key={cardTitle?.text}>
              <OnVisible className={styles.item} visibleClassName={styles.visibleItem}>
                <Image image={image} className={styles.image} />
                <div className={styles.textContainer}>
                  {cardTitle?.text && (
                    <div dangerouslySetInnerHTML={{ __html: cardTitle?.html }} className={styles.title} />
                  )}
                  {subtitle?.text && (
                    <div dangerouslySetInnerHTML={{ __html: subtitle?.html }} className={styles.subtitle} />
                  )}
                  {content?.text && (
                    <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />
                  )}
                </div>
              </OnVisible>
            </Fragment>
          );
        })}
      </div>
    </Section>
  );
};
export default TwoColsTextImage;
