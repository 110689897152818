import React from 'react';
import { Section, Image, Cta, Noise } from 'components';

import * as styles from './styles.module.scss';

const HeadlineText = (props) => {
  const { data, isFilms } = props;
  const { primary } = data;
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    image_effect: imageEffect,
    headline_text: headlineText,
    light_background: lightBg,
  } = primary;

  const hasCta = ctaText?.text;
  const lightBgClass = lightBg ? styles.lightBg : '';

  return (
    <Section
      containerClassName={styles.container}
      className={`${lightBgClass || ''} ${styles.section}`}
      sliceName="HeadlineText"
    >
      {isFilms && <Noise />}
      <div>
        <div dangerouslySetInnerHTML={{ __html: headlineText?.html }} className={styles.titleContainer} />
        {hasCta && (
          <div className={styles.ctaRow}>
            <Cta text={ctaText.text} url={ctaLink?.url} isPinkArrow isNoLink />
          </div>
        )}
        {imageEffect?.url && (
          <div className={styles.imageEffectContainer}>
            <Image image={imageEffect} />
          </div>
        )}
      </div>
    </Section>
  );
};

export default HeadlineText;
