import React from 'react';
import { Section, Link } from 'components';
import { TextSlashes } from './components';
import siteServices from '../../helpers/siteServices';
import { ReactComponent as CtaArrowRight } from '../../images/icons/arrow-right.svg';
import * as styles from './styles.module.scss';

const isFilms = process.env.GATSBY_SITE_NAME === 'films';

const ProjectInfo = (props) => {
  const { data } = props;

  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    description,
    industries,
    products,
    professionals,
    recognitions,
    services,
    page_type: pageType,
  } = data;

  const hasCta = ctaText?.text;

  let servicesToUse = [];

  // IF NO SELECT SERVICE
  const plainTextServices = services.map((item) => item.service);

  if (!services[0]?.service_select) {
    servicesToUse = plainTextServices;
  }

  // IF BOTH
  if (pageType === 'Both') {
    if (isFilms) {
      services.forEach((item) => {
        if (siteServices.films.includes(item?.service_select)) {
          servicesToUse.push(item?.service_select);
        }
      });
    } else {
      services.forEach((item) => {
        if (siteServices.agency.includes(item?.service_select)) {
          servicesToUse.push(item?.service_select);
        }
      });
    }
  } else {
    const allSelectServices = services.map((item) => item.service_select);
    servicesToUse = allSelectServices;
  }

  const serviceProductIndustry = [
    { data: servicesToUse },
    { title: 'PRODUCTS', data: products },
    { title: 'INDUSTRY', data: industries },
  ];

  return (
    <Section containerClassName={styles.container} className={styles.containerClass} sliceName="ProjectInfo">
      <p className={styles.subTitle}>{title?.text}</p>
      <div className={styles.wrapped}>
        <>
          <div className={styles.row}>
            {/* LEFT COL */}
            <div className={isFilms ? styles.columnItems : styles.columnItemsAgency}>
              {/* CREDITS */}
              {isFilms && (
                <div className={styles.credits}>
                  {professionals?.map((profession) => {
                    const split = profession?.profession.text.split(':');
                    const jobTitle = split[0];
                    const professional = split[1];
                    return (
                      <div className={styles.item}>
                        <p className={styles.itemTitle}>{jobTitle}</p>
                        <p className={styles.value}>{professional}</p>
                      </div>
                    );
                  })}
                </div>
              )}
              {/* INDUSTRY/CATEGORIES */}
              {serviceProductIndustry?.map((product) => (
                <TextSlashes product={product} />
              ))}
              {/* AWARDS/RECOGNITIONS */}
              {!!recognitions?.length && (
                <div className={styles.recognitionContainer}>
                  <h3>Recognition </h3>
                  {recognitions?.map((recognition) => {
                    const split = recognition?.recognition.text.split(':');
                    const jobTitle = split[0];
                    const recognitional = split[1];
                    return (
                      <div className={styles.item}>
                        <p>{jobTitle}</p>
                        <CtaArrowRight height={35} width={30} className={styles.arrowRight} />
                        <p>{recognitional}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {/* RIGHT COLUMN */}
            <div className={isFilms ? styles.column : styles.columnAgency}>
              <div dangerouslySetInnerHTML={{ __html: description?.html }} className={styles.description} />
              {hasCta && (
                <Link to={ctaLink?.url}>
                  <button type="submit" className={styles.mainCta}>
                    {ctaText?.text}
                  </button>
                </Link>
              )}
            </div>
          </div>
        </>
      </div>
    </Section>
  );
};
export default ProjectInfo;
