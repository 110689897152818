import React from 'react';
import { TitleLeft, TitleCenter, TitleBottom } from '.';

const Template = ({ primary, isProjectGallery }) => {
  const { title_alignment: alignment } = primary;

  if (alignment === 'Left') {
    return <TitleLeft primary={primary} isProjectGallery={isProjectGallery} />;
  }

  if (alignment === 'Center') {
    return <TitleCenter primary={primary} isProjectGallery={isProjectGallery} />;
  }

  return <TitleBottom primary={primary} isProjectGallery={isProjectGallery} />;
};

export default Template;
