import React from 'react';
import { Cta } from 'components';
import PlayButton from './PlayButton';

import * as styles from '../styles.module.scss';

const Content = ({ primary, isLandingFilms, videoProps, lightBackground }) => {
  const {
    title,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    video_cta_link: videoCtaLink,
    video_cta_text: videoCtaText,
    text_alignment: textAlignment,
    title_colour: titleColour,
    has_play_button: hasPlayButton,
    arrow_text: arrowText,
  } = primary;

  const {
    primary: _primary,
    videoState,
    setVideoState,
    setVideoVisible,
    videoVisible,
    setVideoLoaded,
    isProject,
  } = videoProps;

  const titleHtml = title?.html;
  const contentHtml = content?.html;

  const hasCta = ctaText?.text;
  const hasVideoCta = videoCtaText?.text;
  const isTopText = textAlignment === 'Top';
  const isRightAlignment = textAlignment === 'Bottom Right';

  return (
    <>
      <div className={`${styles.columnWrapper} ${isProject ? styles.isProject : ''}`}>
        <div className={isRightAlignment ? styles.rowReverse : styles.row}>
          {/* =====START==BOTTOM==TEXT======= */}
          <div className={styles.column}>
            {!isTopText && (
              <div className={styles.ctaContainerTop}>
                <div className={styles.CtaContainer}>
                  {hasVideoCta && <Cta text={videoCtaText?.text} url={videoCtaLink.url} mixBlend isLightBox />}
                </div>
              </div>
            )}
            {titleHtml && (
              <div
                dangerouslySetInnerHTML={{ __html: titleHtml }}
                className={titleColour === 'Pink' ? styles.titlePink : styles.title}
              />
            )}
            <div className={styles.content}>
              {contentHtml && (
                <div
                  dangerouslySetInnerHTML={{ __html: contentHtml }}
                  className={isLandingFilms ? styles.textPink : styles.text}
                />
              )}
              {!isTopText && (
                <div className={styles.CtaContainerBottom}>
                  {hasCta && <Cta text={ctaText?.text} url={ctaLink.url} isPinkArrow />}
                </div>
              )}
            </div>
          </div>
          {/* =====START==TOP==TEXT======= */}

          {!isTopText && hasPlayButton && (
            <div className={isRightAlignment ? styles.columnPlayRight : styles.columnPlay}>
              <PlayButton
                primary={primary}
                videoState={videoState}
                setVideoState={setVideoState}
                isTopText={isTopText}
                lightBackground={lightBackground}
              />
            </div>
          )}
        </div>
      </div>
      {arrowText?.text && (
        <Cta text={arrowText?.text} isNoLink isPinkArrow lightBackground darkText className={styles.contentCta} />
      )}

      {/* ========END BOTTOM======== */}

      {isTopText && (
        <div className={isTopText ? styles.videoCtaWrapper : ''}>
          {hasCta && <Cta text={ctaText?.text} url={ctaLink.url} />}
          {hasVideoCta && <Cta text={videoCtaText?.text} url={videoCtaLink.url} mixBlend isPinkArrow />}
        </div>
      )}
    </>
  );
};

export default Content;
