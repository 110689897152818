import React, { useState, useEffect } from 'react';
import { Section, Image, Link, OnVisible } from 'components';
import { use100vh } from 'react-div-100vh';
import * as styles from './styles.module.scss';

const FullscreenTwoCols = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    left_cta_link: ctaLinkLeft,
    left_cta_text: ctaTextLeft,
    left_image: imageLeft,
    left_logo: logoLeft,
    right_cta_link: ctaLinkRight,
    right_cta_text: ctaRightText,
    right_image: imageRight,
    right_logo: logoRight,
  } = primary;

  // HOVER LOGIC
  const [leftHover, setLeftHover] = useState(false);
  const [rightHover, setRightHover] = useState(false);
  const [width, setWidth] = useState(null);
  const [breakPoint, setBreakPoint] = useState(null);

  const handleOnMouseOverLeft = (isHovered) => {
    if (!isHovered && !breakPoint) {
      setLeftHover(true);
    }
  };

  const handleOnMouseOutLeft = (isHovered) => {
    if (isHovered && !breakPoint) {
      setLeftHover(false);
    }
  };

  const handleOnMouseOverRight = (isHovered) => {
    if (!isHovered && !breakPoint) {
      setRightHover(true);
    }
  };

  const handleOnMouseOutRight = (isHovered) => {
    if (isHovered && !breakPoint) {
      setRightHover(false);
    }
  };

  const leftHoverClass = leftHover ? styles.leftHover : '';
  const rightHoverClass = rightHover ? styles.rightHover : '';

  // multi-layered assets for animtions
  const RightLogo = () => (
    <>
      <Image image={logoRight} className={`${styles.rightLogo} ${styles.rightLogoMain}`} />
      <Image image={logoRight} className={`${styles.rightLogo} ${styles.rightLogoBlue}`} />
      <Image image={logoRight} className={`${styles.rightLogo} ${styles.rightLogoPink}`} />
      <Image image={logoRight} className={`${styles.rightLogo} ${styles.rightLogoGreen}`} />
    </>
  );

  const LeftLogo = () => (
    <>
      <Image image={logoLeft} className={`${styles.leftLogo} ${styles.leftLogoMain}`} />
      <Image image={logoLeft} className={`${styles.leftLogo} ${styles.leftLogoAfter}`} />
    </>
  );

  // window listener
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);
  // set breakpoint state
  useEffect(() => {
    const widthRef = width || window.innerWidth;
    const breakpoint = 660;
    if (widthRef <= breakpoint) {
      setBreakPoint(true);
      setLeftHover(true);
      setRightHover(true);
    }
  }, [width]);

  const height = use100vh();

  return (
    <Section className={`${leftHoverClass} ${rightHoverClass} ${styles.section}`} noContainer style={{ height }}>
      <OnVisible className={styles.container} visibleClassName={styles.visibleContainer}>
        {/* LEFT COL */}
        <div
          onMouseOver={() => handleOnMouseOverLeft(leftHover)}
          onMouseOut={() => handleOnMouseOutLeft(leftHover)}
          className={styles.leftImageContainer}
        >
          <Link to={ctaLinkLeft.url}>
            <Image image={imageLeft} alt={imageLeft?.alt} className={styles.leftImage} />
            <div className={styles.leftLogoAlign}>
              <LeftLogo />
            </div>
            <div className={styles.ctaLeft}>
              <p className={styles.ctaText}>{ctaTextLeft.text}</p>
              <Link to={ctaLinkLeft.url} className={styles.cta}>
                rhythmagency.au
              </Link>
            </div>
          </Link>
        </div>
        {/* RIGHT COL */}
        <div
          onMouseOver={() => handleOnMouseOverRight(rightHover)}
          onMouseOut={() => handleOnMouseOutRight(rightHover)}
          className={styles.rightImageContainer}
        >
          <Link to={ctaLinkRight.url}>
            <Image image={imageRight} alt={imageRight?.alt} className={styles.rightImage} />
            <div className={styles.rightLogoAlign}>
              <RightLogo className={styles.rightLogoComp} />
            </div>
            <div className={styles.ctaRight}>
              <p className={styles.ctaText}>{ctaRightText.text}</p>
              <Link to={ctaLinkRight.url} className={styles.cta}>
                rhythmfilms.au
              </Link>
            </div>
          </Link>
        </div>
      </OnVisible>
    </Section>
  );
};
export default FullscreenTwoCols;
