import React from 'react';
import { Image } from 'components';
import SimpleReactLightbox from 'simple-react-lightbox-pro';
import { StaticImage } from 'gatsby-plugin-image';
import { GalleryBox } from './index';

import * as styles from '../styles.module.scss';

const ImageHalfWidth = ({
  firstSlice,
  isProject,
  image,
  imagePlaceholder,
  lightBoxVideo,
  isLightBox,
  numOfItems,
  isThreeImage,
}) => {
  const numOfCols = numOfItems === 2 ? styles.halfColumn : styles.fullColumn;
  const halfWidth = isThreeImage ? styles.halfColumn : '';

  return (
    <>
      <div className={`${numOfCols} ${halfWidth}`}>
        <div className={styles.imageEffectContainerTop}>
          {firstSlice && isProject && (
            <StaticImage
              alt="pink paint spray"
              placeholder="NONE"
              src="../../../images/spray-accent.png"
              style={{ height: '50em', width: '60em' }}
            />
          )}
        </div>
        <span>
          <Image image={image} className={`"grid"`} />
          <div className={styles.containerGalleryBox}>
            {isLightBox && (
              <SimpleReactLightbox>
                <GalleryBox imagePlaceholder={imagePlaceholder} lightBoxVideo={lightBoxVideo} />
              </SimpleReactLightbox>
            )}
          </div>
        </span>
      </div>
    </>
  );
};

export default ImageHalfWidth;
