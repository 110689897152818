import React, { Fragment } from 'react';
import * as styles from '../styles.module.scss';

const TextSlashes = ({ product }) => {
  if (product?.data?.length === 0) return null;

  return (
    <div className={styles.slashItems}>
      {product?.title && <p className={styles.itemTitle}>{product?.title} </p>}
      {product?.data?.map((item, index) => {
        const itemIsString = typeof item === 'string';
        const itemToUse = itemIsString ? item : item?.product?.text || item?.service_select || item?.industry?.text;
        return (
          <Fragment key={item?.service_select || item?.service_select}>
            <p className={styles.value}>{itemToUse}</p>
            {index !== product?.data?.length - 1 && <p className={styles.slash}>/</p>}
          </Fragment>
        );
      })}
    </div>
  );
};

export default TextSlashes;
