import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox-pro';
import * as styles from '../styles.module.scss';
import { ReactComponent as Play } from '../../../images/icons/play-icon.svg';

const GalleryBox = ({ imagePlaceholder, lightBoxVideo, playButtonClassName }) => {
  const { openLightbox } = useLightbox();

  const lightboxOptions = {
    settings: {
      autoplaySpeed: 3000,
      boxShadow: 'none',
      disableKeyboardControls: false,
      disablePanzoom: false,
      disableWheelControls: false,
      hideControlsAfter: 3000,
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: 'linear',
      overlayColor: 'rgba(9, 9, 9, 0.9)',
      slideAnimationType: 'fade',
      slideSpringValues: [300, 50],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: 'linear',
      usingPreact: false,
    },
    buttons: {
      backgroundColor: 'rgba(9, 9, 9, 0.9)',
      iconColor: '#e81f76',
      iconPadding: '10px',
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: true,
      size: '40px',
    },
    thumbnails: {
      showThumbnails: false,
      thumbnailsAlignment: 'center',
      thumbnailsContainerBackgroundColor: 'transparent',
      thumbnailsContainerPadding: '0',
      thumbnailsGap: '0 5px',
      thumbnailsIconColor: '#ffffff',
      thumbnailsOpacity: 0.4,
      thumbnailsPosition: 'bottom',
      thumbnailsSize: ['100px', '80px'],
    },
  };

  const handleClick = () => {
    openLightbox(0);
  };

  const imageData = getImage(imagePlaceholder?.localFile);

  return (
    <div className={styles.playButtonWrapperContainer} onClick={handleClick} role="button" tabIndex={0}>
      <div className={styles.playButtonContainer}>
        <Play className={`${styles.playButton} ${playButtonClassName}`} />
      </div>
      <SRLWrapper options={lightboxOptions}>
        <div className="imageContainerLightBox">
          <div>
            <a srl_video_loop="true" srl_video_scale="80" href={lightBoxVideo}>
              <img
                src={imageData?.placeholder?.fallback || imagePlaceholder?.url}
                alt="Rhythm"
                style={{ display: 'none' }}
              />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </div>
  );
};

export default GalleryBox;
