import * as THREE from 'three';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

import { useEffect, useRef } from 'react';

const useImageHoverEffect = (image, loaded, useDistortion, isMobile) => {
  const visibilityRef = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      if (!image || !loaded || !useDistortion || isMobile) return null;

      const container = image.parentNode;
      const hasCanvas = container.querySelector('canvas');

      if (!container) {
        return null;
      }

      const bleed = 0;

      const widthToUse = image.width || container.offsetWidth || 900;
      const heightToUse = image.height || container.offsetHeight || 500;

      let camera;
      let scene;
      let renderer;
      let composer;
      let renderPass;
      let customPass;
      let geometry;
      let material;
      let mesh;
      let texture;
      const uMouse = new THREE.Vector2(0, 0);

      function init() {
        if (hasCanvas) return null;

        camera = new THREE.PerspectiveCamera(72, widthToUse / heightToUse, 0.01, 10);
        camera.position.z = 0.87;

        scene = new THREE.Scene();

        const imageAspect = widthToUse / heightToUse || 2.0992063492063493;

        geometry = new THREE.PlaneGeometry(imageAspect, 1);

        material = new THREE.MeshBasicMaterial({
          map: texture,
        });
        mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
        renderer.setClearColor(0x1a181a, 0.1);

        renderer.setSize(widthToUse + bleed, heightToUse + bleed);
        renderer.outputEncoding = THREE.sRGBEncoding;
        container.appendChild(renderer.domElement);

        // post processing
        composer = new EffectComposer(renderer);
        renderPass = new RenderPass(scene, camera);
        composer.addPass(renderPass);

        const myEffect = {
          uniforms: {
            tDiffuse: { value: null },
            resolution: { value: new THREE.Vector2(1, window.innerHeight / window.innerWidth) },

            uMouse: { value: new THREE.Vector2(0, 0) },
            uVelo: { value: 0 },
          },
          vertexShader: `varying vec2 vUv;void main() {vUv = uv;gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );}`,
          fragmentShader: `uniform float time;
          uniform sampler2D tDiffuse;
          uniform vec2 resolution;
          varying vec2 vUv;
          uniform vec2 uMouse;
          float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
            uv -= disc_center;
            uv*=resolution;
            float dist = sqrt(dot(uv, uv));
            return smoothstep(disc_radius+border_size, disc_radius-border_size, dist);
          }
          void main()  {
              vec2 newUV = vUv;
              float c = circle(vUv, uMouse, 0.0, 0.2);
              float r = texture2D(tDiffuse, newUV.xy += c * (0.1 * .5)).x;
              float g = texture2D(tDiffuse, newUV.xy += c * (0.1 * .525)).y;
              float b = texture2D(tDiffuse, newUV.xy += c * (0.1 * .55)).z;
              vec4 color = vec4(r, g, b, 1.);
              gl_FragColor = color;
              gl_FragColor.a = 0.1;
          }`,
        };

        customPass = new ShaderPass(myEffect);
        customPass.renderToScreen = true;
        composer.addPass(customPass);
      }

      document.addEventListener('mousemove', (e) => {
        const inView = container.classList.contains('visible');
        // mousemove / touchmove
        uMouse.x = inView ? e.clientX / window.innerWidth : uMouse.x;
        uMouse.y = inView ? 1 - e.clientY / window.innerHeight : uMouse.x;
      });

      function animate() {
        customPass.uniforms.uMouse.value = uMouse;
        requestAnimationFrame(animate);

        composer.render();
      }

      if (!hasCanvas) {
        const dummyimg = document.createElement('img');
        dummyimg.crossOrigin = 'anonymous';
        dummyimg.src = image?.src;
        dummyimg.onload = function () {
          document.body.classList.remove('loading');
          image.style.opacity = 0;
          texture = new THREE.Texture(this);
          texture.needsUpdate = true;

          init();
          animate();
        };
      }
    }, 500);

    //   });
  }, [loaded]);

  return true;
};

export default useImageHoverEffect;
