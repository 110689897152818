import React from 'react';
import { Section, Image, Cta } from 'components';

import * as styles from './styles.module.scss';

const ThreeByThreeImageGrid = (props) => {
  const { data } = props;
  const { items, primary } = data;

  const { cta_link: ctaLink, cta_text: ctaText } = primary;

  const Template = () => (
    <div className={styles.rowWrapper}>
      <div className={styles.row}>
        {items?.map((item) => {
          const { image } = item;

          return (
            <>
              <div className={styles.column}>{image && <Image image={image} />}</div>
            </>
          );
        })}
      </div>
    </div>
  );

  return (
    <Section containerClassName={styles.container} className={styles.containerClass} sliceName="ThreeByThreeImageGrid">
      <div className={styles.wrapped}>
        <div className={styles.ctaContainer}>
          <Cta text={ctaText?.text} url={ctaLink?.url} isPinkArrow />
        </div>
        <Template />
      </div>
    </Section>
  );
};
export default ThreeByThreeImageGrid;
