import React from 'react';
import { Image } from 'components';
import * as styles from '../styles.module.scss';

const ImageColumn = ({ image }) => (
  <div className={styles.column}>
    <div className={styles.imageContainer}>
      <Image image={image} />
    </div>
  </div>
);

export default ImageColumn;
