import React, { Fragment } from 'react';
import { useInView } from 'react-intersection-observer';
import { Section, Cta } from 'components';
import { ImageQuarterVertical, ImageQuarterHorizontal, ImageHalfOrFullWidth } from './components';
import * as styles from './styles.module.scss';

const GalleryGrid = (props) => {
  const { data, firstSlice, lastSlice, isProject } = props;
  const { items } = data;
  const ctaText = data?.primary?.cta_text?.text;
  const ctaLink = data?.primary?.cta_link?.url;
  const hasCta = ctaText;

  const [ref, inView] = useInView({
    threshold: 0,
    delay: 0,
    triggerOnce: true,
  });

  const Template = () => (
    <div className={styles.row}>
      {items?.map((item, index) => {
        const { image, item_is_text: description } = item;
        const length = items?.length;
        const isText = description?.text;
        const isVerticalImage = index === 0 || index === 1;
        const fourImageGrid = length === 4;
        const threeImageGrid = length === 3;

        const isLightBox = item?.lightbox;
        const lightBoxVideo = item?.lightbox_video_link?.url;

        if (fourImageGrid) {
          return (
            <Fragment key={index}>
              {isVerticalImage && (
                <ImageQuarterVertical
                  firstSlice={firstSlice}
                  isProject={isProject}
                  image={image}
                  index={index}
                  isLightBox={isLightBox}
                  lightBoxVideo={lightBoxVideo}
                />
              )}

              {index === items?.length - 1 && (
                <div className={styles.horizontalQuarter}>
                  <ImageQuarterHorizontal items={items} isLightBox={isLightBox} lightBoxVideo={lightBoxVideo} />
                </div>
              )}
            </Fragment>
          );
        }

        if (threeImageGrid) {
          return (
            <Fragment key={index}>
              {index === 0 && (
                <ImageHalfOrFullWidth
                  firstSlice={firstSlice}
                  isProject={isProject}
                  image={image}
                  lightBoxVideo={lightBoxVideo}
                  isThreeImage
                />
              )}
              {(index === 1 || index === 2) && (
                <ImageQuarterVertical
                  firstSlice={firstSlice}
                  isProject={isProject}
                  image={image}
                  index={index}
                  lightBoxVideo={lightBoxVideo}
                  isLightBox={isLightBox}
                />
              )}
            </Fragment>
          );
        }

        return (
          <Fragment key={index}>
            {isText ? (
              <div className={styles.columnText}>
                <div className={styles.columnTextInner}>
                  <h4>{isText}</h4>
                </div>
              </div>
            ) : (
              <ImageHalfOrFullWidth
                firstSlice={firstSlice}
                isProject={isProject}
                image={image}
                isLightBox={isLightBox}
                lightBoxVideo={lightBoxVideo}
                imagePlaceholder={image}
                numOfItems={items.length}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );

  return (
    <div className="gallery-grid">
      <Section
        containerClassName={inView ? styles.container : styles.containerHidden}
        className={firstSlice ? styles.containerClassPadding : styles.containerClass}
        sliceName="GalleryGrid"
      >
        {hasCta && (
          <div className={styles.ctaContainer}>
            <Cta text={ctaText} url={ctaLink} isPinkArrow isNoLink />
          </div>
        )}
        <div className={lastSlice ? styles.wrapped : null} ref={ref}>
          <Template />
        </div>
      </Section>
    </div>
  );
};
export default GalleryGrid;
