import React from 'react';
import { Section, Link, Noise } from 'components';

import * as styles from './styles.module.scss';

const TwoColsTextCta = (props) => {
  const { data, isBlogPost, isFilms } = props;
  const { primary } = data;
  const {
    cta_text: ctaText,
    cta_link: ctaLink,
    text_left: textLeft,
    text_right: textRight,
    light_background: lightBackground,
    secondary_cta_text: secondaryCtaText,
  } = primary;

  const hasCta = ctaText?.text;
  const url = ctaLink?.url;
  const isLightBg = lightBackground ? styles.lightBg : '';

  return (
    <Section
      containerClassName={styles.container}
      className={`${isLightBg} two-cols-text-cta ${styles.containerClass}`}
      sliceName="TwoColsTextCta"
    >
      {isFilms && !isBlogPost && <Noise />}
      {isBlogPost && <h3 style={{ paddingBottom: '0.3em' }}>PRESS</h3>}
      <div className={isBlogPost ? styles.blogPostRow : styles.row} style={{ color: lightBackground && '#090909' }}>
        <div dangerouslySetInnerHTML={{ __html: textLeft?.html }} className={styles.quote} />
        <div className={styles.column}>
          <div dangerouslySetInnerHTML={{ __html: textRight?.html }} className={styles.textRight} />
          {hasCta && (
            <Link to={url}>
              <span type="submit" className={styles.mainCta}>
                {hasCta}
              </span>
            </Link>
          )}
        </div>
      </div>
    </Section>
  );
};
export default TwoColsTextCta;
